unit uData1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Types, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Grids,
  WEBLib.Grids, WEBLib.ExtCtrls, Vcl.Controls,XData.Web.Client, WEBLib.XLSX;

type
  T_utilityDescriptor = record
    sensortype        : integer;
    metertype         : integer;
    attachedto        : integer;
  end;


type
  TfmData1 = class(TForm)
    WebPanel1: TPanel;
    btnExitPage: TButton;
    btnSaveToExcel: TButton;
    btnDataPeriod: TButton;
    btnDataToday: TButton;
    btdDataOtherDate: TButton;
    WebPanel2: TPanel;
    WebLabel4: TLabel;
    edExcelFilnamn: TEdit;
    WebDateTimePickerSelectDate: TDateTimePicker;
    WebDateTimePickerStart: TDateTimePicker;
    WebDateTimePickerStop: TDateTimePicker;
    WebTimerUtilityData: TTimer;
    WebTimerAtPageStart: TTimer;
    WebStringGrid1: TStringGrid;
    RadioGroupBuildings: TRadioGroup;
    RadioGroupUtilities: TRadioGroup;
    btnClearMemo: TButton;
    cbVisaMemo: TCheckBox;
    WebMemo1: TMemo;
    WebXLSX1: TXLSX;
    procedure btnDataTodayClick(Sender: TObject);
    procedure WebTimerAtPageStartTimer(Sender: TObject);
    procedure WebTimerUtilityDataTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure cbVisaMemoClick(Sender: TObject);
    procedure btnExitPageClick(Sender: TObject);
    procedure btnDataPeriodClick(Sender: TObject);
    procedure btdDataOtherDateClick(Sender: TObject);
    procedure btnSaveToExcelClick(Sender: TObject);
  private
    { Private declarations }
    var
      fid_customer:integer;
    procedure UpdateBuildings(id_customer:integer);
    procedure UpdateUtility(id_customer:integer);

  public
    { Public declarations }
    property setCustomer:integer write fid_customer;

  protected procedure LoadDFMValues; override; end;

var
  fmData1: TfmData1;

implementation

{$R *.dfm}

uses uMain, WEBLib.WebTools, uDataModule, System.UITypes,System.DateUtils ;

var
    utility   : array[0..29] of T_utilityDescriptor;    //max 29 utilites
    building  : array[0..99] of integer;                //max 100 buildings
    customer  : array[0..99] of integer;                //max 100 customers


{ TfmData1 }


procedure TfmData1.UpdateBuildings(id_customer: integer);
var i:integer;

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;
  begin
    RadioGroupBuildings.items.Clear;
    WebStringGrid1.colcount:=2;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      //      WebStringGrid1.cells[0,i]:=SplittedLine[0];
//      WebStringGrid1.cells[1,i]:=SplittedLine[1];
      if (i > 0) then begin
        RadioGroupBuildings.Items.Add(SplittedLine[1]); //namn fastighet + ort
        building[i-1]:= strtoint(splittedLine[0]);      //id_building
      end;
      webmemo1.Lines.Add('RadioGroupBuildings: i, value ' + inttostr(i) + '; ' + inttostr(building[i-1]));

    end;

    if nElements1 > 0 then begin
      RadioGroupBuildings.Items.Add('Alla');
      building[nElements1+1]:=0;
    end;
//    WebStringGrid1.rowcount:=nElements1+1;
    RadioGroupBuildings.Font.Size:=9;
    WebTimerUtilityData.enabled:=true;

  end;
begin
  webmemo1.Lines.Add('Here. id-customer: ' + inttostr(id_customer));
  fmDataModule.XDataWebClient1.RawInvoke('ICustomerService.GetBuildingList',[id_customer],@OnResult);
end;

//------------------------------------------------------------------------------
procedure TfmData1.UpdateUtility(id_customer: integer);
var i:integer;

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;

  begin
    RadioGroupUtilities.items.Clear;
    WebStringGrid1.colcount:=5;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      if (i > 0) then begin //first line is the field names
        RadioGroupUtilities.Items.Add(SplittedLine[4]); //namn fastighet + ort
        utility[i-1].sensortype:=strtoint(SplittedLine[2]);  //inidex must start at 0
        utility[i-1].attachedto:=strtoint(SplittedLine[3]);
      end;
    end;
    RadioGroupUtilities.Font.Size:=9;
  end;

begin
  webmemo1.Lines.Add('Here. id-customer: ' + inttostr(id_customer));
  fmDataModule.XDataWebClient1.RawInvoke('ICustomerService.GetUtilityList',[id_customer],@OnResult);
end;


//------BUTTONS-----------------------------------------------------------------
procedure TfmData1.btdDataOtherDateClick(Sender: TObject);
//brief: get data of selected utility at selected date

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;

  begin
    WebStringGrid1.colcount:=6;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      WebStringGrid1.cells[0,i]:=SplittedLine[0];  // id_sensor
      WebStringGrid1.cells[1,i]:=SplittedLine[1];  // radio
      WebStringGrid1.cells[2,i]:=SplittedLine[2];  // datum
      WebStringGrid1.cells[3,i]:=SplittedLine[3];  // klockan
      WebStringGrid1.cells[4,i]:=SplittedLine[4];  // data senaste
      WebStringGrid1.cells[5,i]:=SplittedLine[5];  // lgh namn
    end;

    WebStringGrid1.ColWidths[0]:=100;
    WebStringGrid1.ColWidths[1]:=100;
    WebStringGrid1.ColWidths[2]:=100;
    WebStringGrid1.ColWidths[3]:=100;
    WebStringGrid1.ColWidths[4]:=100;
    WebStringGrid1.ColWidths[5]:=100;

    WebstringGrid1.FixedFont.Size:=9;
    WebstringGrid1.FixedFont.Style := [TFontStyle.fsBold];

    webmemo1.Lines.Add(GreetResult);
  end;

var
  id_customer:integer;
  id_building:integer;
  id_type_of_sensor:integer;
  id_sensor_attached_to:integer;
  selectedDate:string;
  myDate : TDateTime;

begin
  btnDataToday.color:= clNone;
  btdDataOtherDate.color:=clGreen;
  btnDataPeriod.color:=clNone;
  btnSaveToExcel.color:=clNone;

  myDate:= WebDateTimePickerSelectDate.date;
  webmemo1.lines.add('Datum: ' + FormatDateTime('yyyy-mm-dd', myDate));
  selectedDate:=FormatDateTime('yyyy-mm-dd', myDate);
  id_customer:=fid_customer;
  id_building:=building[RadioGroupBuildings.ItemIndex];
  id_type_of_sensor:= utility[RadioGroupUtilities.ItemIndex].sensortype;
  id_sensor_attached_to:= utility[RadioGroupUtilities.ItemIndex].attachedto;
  webmemo1.lines.add('customer,building, type,attached: ' + inttostr(id_customer) + '; ' +  inttostr(id_building) + '; '+ inttostr(id_type_of_sensor) + '; ' + inttostr(id_sensor_attached_to));

  webstringgrid1.clear;
  fmDataModule.XDataWebClient1.RawInvoke('ICustomerService.GetDataSelectedDate',[id_customer, id_building, id_type_of_sensor, id_sensor_attached_to,selectedDate],@OnResult);
end;

procedure TfmData1.btnDataPeriodClick(Sender: TObject);
//brief: get data of selected utility at selected date

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;
  begin
    WebStringGrid1.colcount:=8;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      WebStringGrid1.cells[0,i]:=SplittedLine[0];  // id_sensor
      WebStringGrid1.cells[1,i]:=SplittedLine[1];  // radio
      WebStringGrid1.cells[2,i]:=SplittedLine[2];  // startdata
      WebStringGrid1.cells[3,i]:=SplittedLine[3];  // slutdata
      WebStringGrid1.cells[4,i]:=SplittedLine[4];  // netto data
      WebStringGrid1.cells[5,i]:=SplittedLine[5];  // id lgh
      WebStringGrid1.cells[6,i]:=SplittedLine[6];  // id-obj
      WebStringGrid1.cells[7,i]:=SplittedLine[7];  // lgh namn
    end;

    WebStringGrid1.ColWidths[0]:=100;
    WebStringGrid1.ColWidths[1]:=100;
    WebStringGrid1.ColWidths[2]:=100;
    WebStringGrid1.ColWidths[3]:=100;
    WebStringGrid1.ColWidths[4]:=100;
    WebStringGrid1.ColWidths[5]:=100;
    WebStringGrid1.ColWidths[6]:=100;
    WebStringGrid1.ColWidths[7]:=100;

    WebstringGrid1.FixedFont.Size:=9;
    WebstringGrid1.FixedFont.Style := [TFontStyle.fsBold];

    webmemo1.Lines.Add(GreetResult);
  end;

var
  id_customer:integer;
  id_building:integer;
  id_type_of_sensor:integer;
  id_sensor_attached_to:integer;
  fLowDate, fHighDate:string;
  myDate : TDateTime;

begin
  btnDataToday.color:= clNone;
  btdDataOtherDate.color:=clNone;
  btnDataPeriod.color:=clGreen;
  btnSaveToExcel.color:=clNone;

  myDate:= WebDateTimePickerStart.date;
  fLowDate:=FormatDateTime('yyyy-mm-dd', myDate);

  myDate:= WebDateTimePickerStop.date;
  fHighDate:=FormatDateTime('yyyy-mm-dd', myDate);

//  fLowDate:=edStartDate.text;
//  fHighDate:=edEndDate.text;
  id_customer:=fid_customer;
  id_building:=building[RadioGroupBuildings.ItemIndex];
  id_type_of_sensor:= utility[RadioGroupUtilities.ItemIndex].sensortype;
  id_sensor_attached_to:= utility[RadioGroupUtilities.ItemIndex].attachedto;
//  webmemo1.lines.add('customer,building, type,attached: ' + inttostr(id_customer) + '; ' +  inttostr(id_building) + '; '+ inttostr(id_type_of_sensor) + '; ' + inttostr(id_sensor_attached_to));

  webstringgrid1.clear;
  fmDataModule.XDataWebClient1.RawInvoke('ICustomerService.GetNetData',[id_customer, id_building, id_type_of_sensor, id_sensor_attached_to, fLowDate, fHighDate],@OnResult);
end;

procedure TfmData1.btnDataTodayClick(Sender: TObject);
  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;
  begin
    WebStringGrid1.colcount:=6;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      WebStringGrid1.cells[0,i]:=SplittedLine[0];  // id_sensor
      WebStringGrid1.cells[1,i]:=SplittedLine[1];  // radio
      WebStringGrid1.cells[2,i]:=SplittedLine[2];  // datum
      WebStringGrid1.cells[3,i]:=SplittedLine[3];  // klockan
      WebStringGrid1.cells[4,i]:=SplittedLine[4];  // data senaste
      WebStringGrid1.cells[5,i]:=SplittedLine[5];  // lgh namn
    end;

    WebStringGrid1.ColWidths[0]:=100;
    WebStringGrid1.ColWidths[1]:=100;
    WebStringGrid1.ColWidths[2]:=100;
    WebStringGrid1.ColWidths[3]:=100;
    WebStringGrid1.ColWidths[4]:=100;
    WebStringGrid1.ColWidths[5]:=100;

    WebstringGrid1.FixedFont.Size:=9;
    WebstringGrid1.FixedFont.Style := [TFontStyle.fsBold];

    webmemo1.Lines.Add(GreetResult);
  end;

var
  id_customer:integer;
  id_building:integer;
  id_type_of_sensor:integer;
  id_sensor_attached_to:integer;

begin
  btnDataToday.color:= clGreen;
  btdDataOtherDate.color:=clNone;
  btnDataPeriod.color:=clNone;
  btnSaveToExcel.color:=clNone;

  id_customer:=fid_customer;
  id_building:=building[RadioGroupBuildings.ItemIndex];
  id_type_of_sensor:= utility[RadioGroupUtilities.ItemIndex].sensortype;
  id_sensor_attached_to:= utility[RadioGroupUtilities.ItemIndex].attachedto;
  webmemo1.lines.add('customer,building, type,attached: ' + inttostr(id_customer) + '; ' +  inttostr(id_building) + '; '+ inttostr(id_type_of_sensor) + '; ' + inttostr(id_sensor_attached_to));

  webstringgrid1.clear;
  fmDataModule.XDataWebClient1.RawInvoke('ICustomerService.GetDataToday',[id_customer, id_building, id_type_of_sensor, id_sensor_attached_to],@OnResult);
end;


procedure TfmData1.btnExitPageClick(Sender: TObject);
begin
  Close;
end;

procedure TfmData1.btnSaveToExcelClick(Sender: TObject);
begin
  with webxlsx1 do begin
    grid:=webstringgrid1;
    gridstartcol:=0;
    gridstartrow:=0;
    save(edExcelFilnamn.text);
  end;
end;

procedure TfmData1.cbVisaMemoClick(Sender: TObject);
begin
  if cbVisaMemo.Checked then begin
    WebMemo1.visible:=true;
    btnClearMemo.visible:=true;
  end else begin
    WebMemo1.visible:=false;
    btnClearMemo.visible:=false;
  end;
end;

//-------TIMERS ETC-------------------------------------------------------------
procedure TfmData1.WebTimerAtPageStartTimer(Sender: TObject);
begin
  WebTimerAtPageStart.enabled:=false;
  UpdateBuildings(fid_customer);
end;

procedure TfmData1.WebTimerUtilityDataTimer(Sender: TObject);
begin
  WebTimerUtilityData.Enabled:=false;
  UpdateUtility(fid_customer);
end;

procedure TfmData1.WebFormShow(Sender: TObject);
begin
  webmemo1.lines.add('customer: ' + inttostr(fid_customer));
  WebTimerAtPageStart.enabled:=true;

  WebDateTimePickerStop.date:=Now-1;
  WebDateTimePickerStart.date:=StartOfTheMonth(Now);
  WebDateTimePickerSelectDate.date:=Now-1;
end;

procedure TfmData1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  btnExitPage := TButton.Create(Self);
  btnSaveToExcel := TButton.Create(Self);
  btnDataPeriod := TButton.Create(Self);
  btnDataToday := TButton.Create(Self);
  btdDataOtherDate := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  edExcelFilnamn := TEdit.Create(Self);
  WebDateTimePickerSelectDate := TDateTimePicker.Create(Self);
  WebDateTimePickerStart := TDateTimePicker.Create(Self);
  WebDateTimePickerStop := TDateTimePicker.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  RadioGroupBuildings := TRadioGroup.Create(Self);
  RadioGroupUtilities := TRadioGroup.Create(Self);
  btnClearMemo := TButton.Create(Self);
  cbVisaMemo := TCheckBox.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebTimerUtilityData := TTimer.Create(Self);
  WebTimerAtPageStart := TTimer.Create(Self);
  WebXLSX1 := TXLSX.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  btnExitPage.BeforeLoadDFMValues;
  btnSaveToExcel.BeforeLoadDFMValues;
  btnDataPeriod.BeforeLoadDFMValues;
  btnDataToday.BeforeLoadDFMValues;
  btdDataOtherDate.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  edExcelFilnamn.BeforeLoadDFMValues;
  WebDateTimePickerSelectDate.BeforeLoadDFMValues;
  WebDateTimePickerStart.BeforeLoadDFMValues;
  WebDateTimePickerStop.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  RadioGroupBuildings.BeforeLoadDFMValues;
  RadioGroupUtilities.BeforeLoadDFMValues;
  btnClearMemo.BeforeLoadDFMValues;
  cbVisaMemo.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebTimerUtilityData.BeforeLoadDFMValues;
  WebTimerAtPageStart.BeforeLoadDFMValues;
  WebXLSX1.BeforeLoadDFMValues;
  try
    Name := 'fmData1';
    Width := 1401;
    Height := 985;
    CSSLibrary := cssBootstrap;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1401;
    WebPanel1.Height := 61;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.Align := alTop;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 6;
    WebPanel1.Color := clBlanchedalmond;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebPanel1.ShowCaption := False;
    WebPanel1.TabOrder := 0;
    btnExitPage.SetParentComponent(WebPanel1);
    btnExitPage.Name := 'btnExitPage';
    btnExitPage.Left := 1269;
    btnExitPage.Top := 14;
    btnExitPage.Width := 96;
    btnExitPage.Height := 41;
    btnExitPage.Caption := 'Tillbaka';
    btnExitPage.ChildOrder := 1;
    btnExitPage.ElementClassName := 'btn btn-light';
    btnExitPage.ElementFont := efCSS;
    btnExitPage.Font.Charset := DEFAULT_CHARSET;
    btnExitPage.Font.Color := clWindowText;
    btnExitPage.Font.Height := -18;
    btnExitPage.Font.Name := 'Segoe UI';
    btnExitPage.Font.Style := [];
    btnExitPage.HeightStyle := ssAuto;
    btnExitPage.HeightPercent := 100.000000000000000000;
    btnExitPage.ParentFont := False;
    btnExitPage.WidthPercent := 100.000000000000000000;
    SetEvent(btnExitPage, Self, 'OnClick', 'btnExitPageClick');
    btnSaveToExcel.SetParentComponent(WebPanel1);
    btnSaveToExcel.Name := 'btnSaveToExcel';
    btnSaveToExcel.Left := 954;
    btnSaveToExcel.Top := 14;
    btnSaveToExcel.Width := 164;
    btnSaveToExcel.Height := 41;
    btnSaveToExcel.Caption := 'Spara i excel';
    btnSaveToExcel.ChildOrder := 12;
    btnSaveToExcel.ElementClassName := 'btn btn-light';
    btnSaveToExcel.ElementFont := efCSS;
    btnSaveToExcel.Font.Charset := DEFAULT_CHARSET;
    btnSaveToExcel.Font.Color := clWindowText;
    btnSaveToExcel.Font.Height := -18;
    btnSaveToExcel.Font.Name := 'Segoe UI';
    btnSaveToExcel.Font.Style := [];
    btnSaveToExcel.HeightStyle := ssAuto;
    btnSaveToExcel.HeightPercent := 100.000000000000000000;
    btnSaveToExcel.ParentFont := False;
    btnSaveToExcel.WidthPercent := 100.000000000000000000;
    SetEvent(btnSaveToExcel, Self, 'OnClick', 'btnSaveToExcelClick');
    btnDataPeriod.SetParentComponent(WebPanel1);
    btnDataPeriod.Name := 'btnDataPeriod';
    btnDataPeriod.Left := 545;
    btnDataPeriod.Top := 14;
    btnDataPeriod.Width := 172;
    btnDataPeriod.Height := 41;
    btnDataPeriod.Caption := 'Data f'#246'r period';
    btnDataPeriod.ChildOrder := 5;
    btnDataPeriod.ElementClassName := 'btn btn-light';
    btnDataPeriod.ElementFont := efCSS;
    btnDataPeriod.Font.Charset := DEFAULT_CHARSET;
    btnDataPeriod.Font.Color := clWindowText;
    btnDataPeriod.Font.Height := -18;
    btnDataPeriod.Font.Name := 'Segoe UI';
    btnDataPeriod.Font.Style := [];
    btnDataPeriod.HeightStyle := ssAuto;
    btnDataPeriod.HeightPercent := 100.000000000000000000;
    btnDataPeriod.ParentFont := False;
    btnDataPeriod.WidthPercent := 100.000000000000000000;
    SetEvent(btnDataPeriod, Self, 'OnClick', 'btnDataPeriodClick');
    btnDataToday.SetParentComponent(WebPanel1);
    btnDataToday.Name := 'btnDataToday';
    btnDataToday.Left := 36;
    btnDataToday.Top := 14;
    btnDataToday.Width := 145;
    btnDataToday.Height := 41;
    btnDataToday.Caption := 'Data idag';
    btnDataToday.ChildOrder := 5;
    btnDataToday.ElementClassName := 'btn btn-light';
    btnDataToday.ElementFont := efCSS;
    btnDataToday.Font.Charset := DEFAULT_CHARSET;
    btnDataToday.Font.Color := clWindowText;
    btnDataToday.Font.Height := -18;
    btnDataToday.Font.Name := 'Segoe UI';
    btnDataToday.Font.Style := [];
    btnDataToday.HeightStyle := ssAuto;
    btnDataToday.HeightPercent := 100.000000000000000000;
    btnDataToday.ParentFont := False;
    btnDataToday.WidthPercent := 100.000000000000000000;
    SetEvent(btnDataToday, Self, 'OnClick', 'btnDataTodayClick');
    btdDataOtherDate.SetParentComponent(WebPanel1);
    btdDataOtherDate.Name := 'btdDataOtherDate';
    btdDataOtherDate.Left := 253;
    btdDataOtherDate.Top := 14;
    btdDataOtherDate.Width := 168;
    btdDataOtherDate.Height := 41;
    btdDataOtherDate.Caption := 'Data annan dag';
    btdDataOtherDate.ChildOrder := 5;
    btdDataOtherDate.ElementClassName := 'btn btn-light';
    btdDataOtherDate.ElementFont := efCSS;
    btdDataOtherDate.Font.Charset := DEFAULT_CHARSET;
    btdDataOtherDate.Font.Color := clWindowText;
    btdDataOtherDate.Font.Height := -18;
    btdDataOtherDate.Font.Name := 'Segoe UI';
    btdDataOtherDate.Font.Style := [];
    btdDataOtherDate.HeightStyle := ssAuto;
    btdDataOtherDate.HeightPercent := 100.000000000000000000;
    btdDataOtherDate.ParentFont := False;
    btdDataOtherDate.WidthPercent := 100.000000000000000000;
    SetEvent(btdDataOtherDate, Self, 'OnClick', 'btdDataOtherDateClick');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 61;
    WebPanel2.Width := 1401;
    WebPanel2.Height := 55;
    WebPanel2.ElementClassName := 'card';
    WebPanel2.Align := alTop;
    WebPanel2.Caption := 'WebPanel2';
    WebPanel2.ChildOrder := 14;
    WebPanel2.Color := clSilver;
    WebPanel2.ElementBodyClassName := 'card-body';
    WebPanel2.ElementFont := efCSS;
    WebPanel2.ShowCaption := False;
    WebPanel2.TabOrder := 1;
    WebLabel4.SetParentComponent(WebPanel2);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 637;
    WebLabel4.Top := 13;
    WebLabel4.Width := 18;
    WebLabel4.Height := 25;
    WebLabel4.Caption := 'till';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -18;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    edExcelFilnamn.SetParentComponent(WebPanel2);
    edExcelFilnamn.Name := 'edExcelFilnamn';
    edExcelFilnamn.Left := 929;
    edExcelFilnamn.Top := 6;
    edExcelFilnamn.Width := 240;
    edExcelFilnamn.Height := 40;
    edExcelFilnamn.ChildOrder := 2;
    edExcelFilnamn.ElementClassName := 'form-control';
    edExcelFilnamn.ElementFont := efCSS;
    edExcelFilnamn.Font.Charset := DEFAULT_CHARSET;
    edExcelFilnamn.Font.Color := clWindowText;
    edExcelFilnamn.Font.Height := -16;
    edExcelFilnamn.Font.Name := 'Segoe UI';
    edExcelFilnamn.Font.Style := [];
    edExcelFilnamn.HeightStyle := ssAuto;
    edExcelFilnamn.HeightPercent := 100.000000000000000000;
    edExcelFilnamn.ParentFont := False;
    edExcelFilnamn.Text := 'min_datafil.xlsx';
    edExcelFilnamn.WidthPercent := 100.000000000000000000;
    WebDateTimePickerSelectDate.SetParentComponent(WebPanel2);
    WebDateTimePickerSelectDate.Name := 'WebDateTimePickerSelectDate';
    WebDateTimePickerSelectDate.Left := 269;
    WebDateTimePickerSelectDate.Top := 5;
    WebDateTimePickerSelectDate.Width := 136;
    WebDateTimePickerSelectDate.Height := 41;
    WebDateTimePickerSelectDate.ElementClassName := 'form-control';
    WebDateTimePickerSelectDate.HeightStyle := ssAuto;
    WebDateTimePickerSelectDate.BorderStyle := bsSingle;
    WebDateTimePickerSelectDate.ChildOrder := 11;
    WebDateTimePickerSelectDate.Color := clWhite;
    WebDateTimePickerSelectDate.Date := 45567.730063796300000000;
    WebDateTimePickerSelectDate.ElementFont := efCSS;
    WebDateTimePickerSelectDate.Role := '';
    WebDateTimePickerSelectDate.Text := '';
    WebDateTimePickerStart.SetParentComponent(WebPanel2);
    WebDateTimePickerStart.Name := 'WebDateTimePickerStart';
    WebDateTimePickerStart.Left := 495;
    WebDateTimePickerStart.Top := 5;
    WebDateTimePickerStart.Width := 136;
    WebDateTimePickerStart.Height := 41;
    WebDateTimePickerStart.ElementClassName := 'form-control';
    WebDateTimePickerStart.HeightStyle := ssAuto;
    WebDateTimePickerStart.BorderStyle := bsSingle;
    WebDateTimePickerStart.ChildOrder := 11;
    WebDateTimePickerStart.Color := clWhite;
    WebDateTimePickerStart.Date := 45567.730063796300000000;
    WebDateTimePickerStart.ElementFont := efCSS;
    WebDateTimePickerStart.Role := '';
    WebDateTimePickerStart.Text := '';
    WebDateTimePickerStop.SetParentComponent(WebPanel2);
    WebDateTimePickerStop.Name := 'WebDateTimePickerStop';
    WebDateTimePickerStop.Left := 669;
    WebDateTimePickerStop.Top := 5;
    WebDateTimePickerStop.Width := 136;
    WebDateTimePickerStop.Height := 41;
    WebDateTimePickerStop.ElementClassName := 'form-control';
    WebDateTimePickerStop.HeightStyle := ssAuto;
    WebDateTimePickerStop.BorderStyle := bsSingle;
    WebDateTimePickerStop.ChildOrder := 11;
    WebDateTimePickerStop.Color := clWhite;
    WebDateTimePickerStop.Date := 45567.730063796300000000;
    WebDateTimePickerStop.ElementFont := efCSS;
    WebDateTimePickerStop.Role := '';
    WebDateTimePickerStop.Text := '';
    WebStringGrid1.SetParentComponent(Self);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.Left := 8;
    WebStringGrid1.Top := 144;
    WebStringGrid1.Width := 709;
    WebStringGrid1.Height := 541;
    WebStringGrid1.ColCount := 12;
    WebStringGrid1.FixedCols := 0;
    WebStringGrid1.RowCount := 500;
    WebStringGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect,goFixedRowDefAlign];
    WebStringGrid1.TabOrder := 2;
    WebStringGrid1.FixedFont.Charset := DEFAULT_CHARSET;
    WebStringGrid1.FixedFont.Color := clWindowText;
    WebStringGrid1.FixedFont.Height := -18;
    WebStringGrid1.FixedFont.Name := 'Segoe UI';
    WebStringGrid1.FixedFont.Style := [];
    WebStringGrid1.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid1.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid1.HeightPercent := 100.000000000000000000;
    WebStringGrid1.WidthPercent := 100.000000000000000000;
    WebStringGrid1.ColWidths[0] := 64;
    WebStringGrid1.ColWidths[1] := 64;
    WebStringGrid1.ColWidths[2] := 64;
    WebStringGrid1.ColWidths[3] := 64;
    WebStringGrid1.ColWidths[4] := 64;
    WebStringGrid1.ColWidths[5] := 64;
    WebStringGrid1.ColWidths[6] := 64;
    WebStringGrid1.ColWidths[7] := 64;
    WebStringGrid1.ColWidths[8] := 64;
    WebStringGrid1.ColWidths[9] := 64;
    WebStringGrid1.ColWidths[10] := 64;
    WebStringGrid1.ColWidths[11] := 64;
    RadioGroupBuildings.SetParentComponent(Self);
    RadioGroupBuildings.Name := 'RadioGroupBuildings';
    RadioGroupBuildings.Left := 757;
    RadioGroupBuildings.Top := 144;
    RadioGroupBuildings.Width := 242;
    RadioGroupBuildings.Height := 541;
    RadioGroupBuildings.ElementClassName := 'custom-control custom-radio';
    RadioGroupBuildings.Caption := 'Hus';
    RadioGroupBuildings.ChildOrder := 5;
    RadioGroupBuildings.Columns := 1;
    RadioGroupBuildings.ElementButtonClassName := 'custom-control-input';
    RadioGroupBuildings.ElementGroupClassName := 'modal-content';
    RadioGroupBuildings.ElementLabelClassName := 'custom-control-label';
    RadioGroupBuildings.ElementLegendClassName := 'h6';
    RadioGroupBuildings.ElementFont := efCSS;
    RadioGroupBuildings.ItemIndex := 0;
    RadioGroupBuildings.Role := '';
    RadioGroupBuildings.TabOrder := 3;
    RadioGroupUtilities.SetParentComponent(Self);
    RadioGroupUtilities.Name := 'RadioGroupUtilities';
    RadioGroupUtilities.Left := 1016;
    RadioGroupUtilities.Top := 144;
    RadioGroupUtilities.Width := 313;
    RadioGroupUtilities.Height := 541;
    RadioGroupUtilities.ElementClassName := 'custom-control custom-radio';
    RadioGroupUtilities.Caption := 'Vad vill du se';
    RadioGroupUtilities.ChildOrder := 5;
    RadioGroupUtilities.Columns := 1;
    RadioGroupUtilities.ElementButtonClassName := 'custom-control-input';
    RadioGroupUtilities.ElementGroupClassName := 'modal-content';
    RadioGroupUtilities.ElementLabelClassName := 'custom-control-label';
    RadioGroupUtilities.ElementLegendClassName := 'h6';
    RadioGroupUtilities.ElementFont := efCSS;
    RadioGroupUtilities.ItemIndex := 0;
    RadioGroupUtilities.Role := '';
    RadioGroupUtilities.TabOrder := 4;
    btnClearMemo.SetParentComponent(Self);
    btnClearMemo.Name := 'btnClearMemo';
    btnClearMemo.Left := 757;
    btnClearMemo.Top := 756;
    btnClearMemo.Width := 145;
    btnClearMemo.Height := 41;
    btnClearMemo.Caption := 'Clear memo';
    btnClearMemo.ChildOrder := 5;
    btnClearMemo.ElementClassName := 'btn btn-light';
    btnClearMemo.ElementFont := efCSS;
    btnClearMemo.HeightStyle := ssAuto;
    btnClearMemo.HeightPercent := 100.000000000000000000;
    btnClearMemo.Visible := False;
    btnClearMemo.WidthPercent := 100.000000000000000000;
    cbVisaMemo.SetParentComponent(Self);
    cbVisaMemo.Name := 'cbVisaMemo';
    cbVisaMemo.Left := 757;
    cbVisaMemo.Top := 803;
    cbVisaMemo.Width := 337;
    cbVisaMemo.Height := 22;
    cbVisaMemo.Caption := 'Visa Memo-f'#228'ltet';
    cbVisaMemo.ChildOrder := 7;
    cbVisaMemo.ElementClassName := 'custom-control custom-checkbox';
    cbVisaMemo.ElementButtonClassName := 'custom-control-input';
    cbVisaMemo.ElementLabelClassName := 'custom-control-label';
    cbVisaMemo.ElementFont := efCSS;
    cbVisaMemo.HeightStyle := ssAuto;
    cbVisaMemo.HeightPercent := 100.000000000000000000;
    cbVisaMemo.WidthPercent := 100.000000000000000000;
    SetEvent(cbVisaMemo, Self, 'OnClick', 'cbVisaMemoClick');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 717;
    WebMemo1.Width := 709;
    WebMemo1.Height := 238;
    WebMemo1.ElementClassName := 'form-control';
    WebMemo1.ElementFont := efCSS;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.Visible := False;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebTimerUtilityData.SetParentComponent(Self);
    WebTimerUtilityData.Name := 'WebTimerUtilityData';
    WebTimerUtilityData.Enabled := False;
    WebTimerUtilityData.Interval := 500;
    SetEvent(WebTimerUtilityData, Self, 'OnTimer', 'WebTimerUtilityDataTimer');
    WebTimerUtilityData.Left := 1224;
    WebTimerUtilityData.Top := 100;
    WebTimerAtPageStart.SetParentComponent(Self);
    WebTimerAtPageStart.Name := 'WebTimerAtPageStart';
    WebTimerAtPageStart.Enabled := False;
    SetEvent(WebTimerAtPageStart, Self, 'OnTimer', 'WebTimerAtPageStartTimer');
    WebTimerAtPageStart.Left := 1352;
    WebTimerAtPageStart.Top := 100;
    WebXLSX1.SetParentComponent(Self);
    WebXLSX1.Name := 'WebXLSX1';
    WebXLSX1.Left := 1280;
    WebXLSX1.Top := 72;
  finally
    WebPanel1.AfterLoadDFMValues;
    btnExitPage.AfterLoadDFMValues;
    btnSaveToExcel.AfterLoadDFMValues;
    btnDataPeriod.AfterLoadDFMValues;
    btnDataToday.AfterLoadDFMValues;
    btdDataOtherDate.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    edExcelFilnamn.AfterLoadDFMValues;
    WebDateTimePickerSelectDate.AfterLoadDFMValues;
    WebDateTimePickerStart.AfterLoadDFMValues;
    WebDateTimePickerStop.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    RadioGroupBuildings.AfterLoadDFMValues;
    RadioGroupUtilities.AfterLoadDFMValues;
    btnClearMemo.AfterLoadDFMValues;
    cbVisaMemo.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebTimerUtilityData.AfterLoadDFMValues;
    WebTimerAtPageStart.AfterLoadDFMValues;
    WebXLSX1.AfterLoadDFMValues;
  end;
end;

end.