program Project1;

uses
  Vcl.Forms,
  WEBLib.Forms,
  uMain in 'uMain.pas'{*.html},
  uData1 in 'uData1.pas'{*.html},
  uDataModule in 'uDataModule.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TfmMain, fmMain);
  Application.CreateForm(TfmData1, fmData1);
  Application.CreateFormDirect(TfmDataModule, fmDataModule);
  Application.Run;
end.
