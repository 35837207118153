{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2022 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.XLSX;

{$DEFINE NOPP}

interface

uses
  Classes, WEBLib.Controls, libexceljs, js, sysutils, WEBLib.Grids, web, Types,
  WEBLib.Graphics;

type
  TCustomGridProtected = class(TCustomGrid);
  TXLSXCell = class;

  TJSExcelJSCellRecord = record
    cell: TJSExcelJSCell;
  end;

  TXLSXSheetLoadedEvent = procedure(Sender: TObject; ASheetName: string) of object;
  TXLSXSaveCellEvent = procedure(Sender: TObject; ARow: Integer; AColumn: Integer; AContent: string; var ACell: TJSExcelJSCellRecord) of object;
  TXLSXLoadCellEvent = procedure(Sender: TObject; ARow: Integer; AColumn: Integer; var AContent: string; ACell: TJSExcelJSCellRecord) of object;

  TXLSXStyleFontStyle = (xfsBold, xfsItalic, xfsStrike, xfsOutline);
  TXLSXStyleFontStyles = set of TXLSXStyleFontStyle;
  TXLSXStyleFontScheme = (xfscMinor, xfscMajor, xfscNone);
  TXLSXStyleFontAlign = (xfaDefault, xfaSuperscript, xfaSubscript);
  TXLSXStyleFontUnderline = (xfuNone, xfuSingle, xfuDouble, xfuSingleAccounting, xfuDoubleAccounting);

  TXLSXStyleFont = class(TObject)
  private
    FCell: TJSExcelJSCell;
    function GetColor: TColor;
    function GetName: string;
    function GetSize: Integer;
    function GetStyles: TXLSXStyleFontStyles;
    procedure SetColor(const Value: TColor);
    procedure SetName(const Value: string);
    procedure SetSize(const Value: Integer);
    procedure SetStyles(const Value: TXLSXStyleFontStyles);
    function GetCharset: Integer;
    procedure SetCharset(const Value: Integer);
    function GetVertAlign: TXLSXStyleFontAlign;
    procedure SetVertAlign(const Value: TXLSXStyleFontAlign);
    function GetTheme: Integer;
    procedure SetTheme(const Value: Integer);
    function GetUnderline: TXLSXStyleFontUnderline;
    procedure SetUnderline(const Value: TXLSXStyleFontUnderline);
  public
    constructor Create(ACell: TXLSXCell);
    property Theme: Integer read GetTheme write SetTheme;
    property Name: string read GetName write SetName;
    property Size: Integer read GetSize write SetSize;
    property Color: TColor read GetColor write SetColor;
    property Style: TXLSXStyleFontStyles read GetStyles write SetStyles;
    property Charset: Integer read GetCharset write SetCharset;
    property VerticalAlign: TXLSXStyleFontAlign read GetVertAlign write SetVertAlign;
    property Underline: TXLSXStyleFontUnderline read GetUnderline write SetUnderline;
  end;

  TXLSXStyleVerticalAlignment = (xvaTop, xvaMiddle, xvaBottom, xvaDistributed, xvaJustify);
  TXLSXStyleHorizontalAlignment = (xhaLeft, xhaCenter, xhaRight, xhaFill, xhaJustify, xhaCenterCont, xhaDistributed);
  TXLSXStyleReadingOrder = (xroRTL, xroLTR);

  TXLSXStyleAlignment = class(TObject)
  private
    FCell: TJSExcelJSCell;
    function GetHorizontal: TXLSXStyleHorizontalAlignment;
    function GetIndent: Integer;
    function GetReadingOrder: TXLSXStyleReadingOrder;
    function GetShrinkTofIt: Boolean;
    function GetTextRotation: Integer;
    function GetVertical: TXLSXStyleVerticalAlignment;
    function GetWrapText: Boolean;
    procedure SetHorizontal(const Value: TXLSXStyleHorizontalAlignment);
    procedure SetIndent(const Value: Integer);
    procedure SetReadingOrder(const Value: TXLSXStyleReadingOrder);
    procedure SetShrinkToFit(const Value: Boolean);
    procedure SetTextRotation(const Value: Integer);
    procedure SetVertical(const Value: TXLSXStyleVerticalAlignment);
    procedure SetWrapText(const Value: Boolean);
    function GetVerticalText: Boolean;
    procedure SetVerticalText(const Value: Boolean);
  public
    constructor Create(ACell: TXLSXCell);
    property Vertical: TXLSXStyleVerticalAlignment read GetVertical write SetVertical;
    property Horizontal: TXLSXStyleHorizontalAlignment read GetHorizontal write SetHorizontal;
    property WrapText: Boolean read GetWrapText write SetWrapText;
    property ShrinkToFit: Boolean read GetShrinkTofIt write SetShrinkToFit;
    property Indent: Integer read GetIndent write SetIndent;
    property ReadingOrder: TXLSXStyleReadingOrder read GetReadingOrder write SetReadingOrder;
    property TextRotationAngle: Integer read GetTextRotation write SetTextRotation;
    property VerticalText: Boolean read GetVerticalText write SetVerticalText;
  end;

  TXLSXStyleBorder = class;

  TXLSXStyleBorderType = (btTop, btLeft, btBottom, btRight, btDiagonal);

  TXLSXStyleBorderStyle = (xbsNone, xbsThin, xbsDotted, xbsDashDot, xbsHair, xbsDashDotDot,
    xbsSlantDashDot, xbsMediumDashed, xbsMediumDashDotDot, xbsMediumDashDot,
    xbsMedium, xbsDouble, xbsThick);

  TXLSXStyleBorderBase = class(TObject)
  private
    FOwner: TXLSXStyleBorder;
    FBorder: TXLSXStyleBorderType;
    function GetColor: TColor;
    function GetStyle: TXLSXStyleBorderStyle;
    function GetTheme: Integer;
    procedure SetColor(const Value: TColor);
    procedure SetStyle(const Value: TXLSXStyleBorderStyle);
    procedure SetTheme(const Value: Integer);
  public
    constructor Create(AOwner: TXLSXStyleBorder);
    property Style: TXLSXStyleBorderStyle read GetStyle write SetStyle;
    property Color: TColor read GetColor write SetColor;
    property Theme: Integer read GetTheme write SetTheme;
  end;

  TXLSXStyleBorderDiagonal = class(TXLSXStyleBorderBase)
  private
    function GetDown: Boolean;
    function GetUp: Boolean;
    procedure SetDown(const Value: Boolean);
    procedure SetUp(const Value: Boolean);
  public
    property Up: Boolean read GetUp write SetUp;
    property Down: Boolean read GetDown write SetDown;
  end;

  TXLSXStyleBorder = class(TObject)
  private
    FCell: TJSExcelJSCell;
    FRight: TXLSXStyleBorderBase;
    FBottom: TXLSXStyleBorderBase;
    FTop: TXLSXStyleBorderBase;
    FDiagonal: TXLSXStyleBorderDiagonal;
    FLeft: TXLSXStyleBorderBase;
    procedure SetBottom(const Value: TXLSXStyleBorderBase);
    procedure SetDiagonal(const Value: TXLSXStyleBorderDiagonal);
    procedure SetLeft(const Value: TXLSXStyleBorderBase);
    procedure SetRight(const Value: TXLSXStyleBorderBase);
    procedure SetTop(const Value: TXLSXStyleBorderBase);
  public
    constructor Create(ACell: TXLSXCell);
    destructor Destroy; override;
    property Top: TXLSXStyleBorderBase read FTop write SetTop;
    property Left: TXLSXStyleBorderBase read FLeft write SetLeft;
    property Bottom: TXLSXStyleBorderBase read FBottom write SetBottom;
    property Right: TXLSXStyleBorderBase read FRight write SetRight;
    property Diagonal: TXLSXStyleBorderDiagonal read FDiagonal write SetDiagonal;
  end;

  TXLSXStyleProtection = class(TObject)
  private
    FCell: TJSExcelJSCell;
    function GetHidden: Boolean;
    function GetLocked: Boolean;
    procedure SetHidden(const Value: Boolean);
    procedure SetLocked(const Value: Boolean);
  public
    constructor Create(ACell: TXLSXCell);
    property Locked: Boolean read GetLocked write SetLocked;
    property Hidden: Boolean read GetHidden write SetHidden;
  end;

  TXLSXCell = class(TObject)
  private
    FCell: TJSExcelJSCell;
    FFont: TXLSXStyleFont;
    FAlignment: TXLSXStyleAlignment;
    FBorder: TXLSXStyleBorder;
    FProtection: TXLSXStyleProtection;
    function GetNames: TStringDynArray;
    function GetNumericFormat: string;
    function GetText: string;
    function GetType: Integer;
    function GetValue: JSValue;
    procedure SetNames(const Value: TStringDynArray);
    procedure SetNumericFormat(const Value: string);
    procedure SetType(const Value: Integer);
    procedure SetValue(const Value: JSValue);
    procedure SetFont(const Value: TXLSXStyleFont);
    procedure SetAlignment(const Value: TXLSXStyleAlignment);
    procedure SetBorder(const Value: TXLSXStyleBorder);
    procedure SetProtection(const Value: TXLSXStyleProtection);
    function GetFill: TColor;
    procedure SetFill(const Value: TColor);
  public
    constructor Create(ACell: TJSExcelJSCell);
    destructor Destroy; override;
    procedure AddName(AName: string);
    procedure RemoveName(AName: string);
    property Text: string read GetText;
    property Value: JSValue read GetValue write SetValue;
    property CellType: Integer read GetType write SetType;
    property Names: TStringDynArray read GetNames write SetNames;
    property NumericFormat: string read GetNumericFormat write SetNumericFormat;
    property Font: TXLSXStyleFont read FFont write SetFont;
    property Alignment: TXLSXStyleAlignment read FAlignment write SetAlignment;
    property Border: TXLSXStyleBorder read FBorder write SetBorder;
    property Protection: TXLSXStyleProtection read FProtection write SetProtection;
    property Fill: TColor read GetFill write SetFill;
  end;

  TXLSXCellArray = array of TXLSXCell;

  TXLSX = class(TComponent)
  private
    FWorkbook: TJSExcelJSWorkbook;
    FCreator: string;
    FLastModifiedBy: string;
    FActiveSheet: string;
    FCurrentSheet: TJSExcelJSWorksheet;
    FOnWorkbookLoaded: TNotifyEvent;
    FGrid: TControl;
    FGridStartCol: Integer;
    FGridStartRow: Integer;
    FXlsxStartCol: Integer;
    FXlsxStartRow: Integer;
    FConvertNumbers: Boolean;
    FOnSheetLoaded: TXLSXSheetLoadedEvent;
    FOnNewSheetAdded: TXLSXSheetLoadedEvent;
    FOnSaveCell: TXLSXSaveCellEvent;
    FOnLoadCell: TXLSXLoadCellEvent;
    FExportResolve: TJSPromiseResolver;
    function GetSheetName(Index: Integer): string;
    procedure SetSheetName(Index: Integer; const Value: string);
    function GetSheetNameCount: Integer;
    function GetActiveSheet: string;
    procedure SetActiveSheet(const Value: string);
    procedure SetGridStartCol(const Value: Integer);
    procedure SetGridStartRow(const Value: Integer);
    procedure SetXlsxStartCol(const Value: Integer);
    procedure SetXlsxStartRow(const Value: Integer);
    function GetCreator: string;
    function GetLastModifiedBy: string;
    procedure SetCreator(const Value: string);
    procedure SetLastModifiedBy(const Value: string);
    procedure SetGrid(const Value: TControl);
    function GetCellObject(ACol, ARow: Integer): TXLSXCell;
    function GetCellAsString(ACol, ARow: Integer): string;
  protected
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure InitWorkbook;
    procedure LoadCurrentSheet;
    procedure SaveCurrentSheet;
    function HandleWorkbookLoaded(AValue: JSValue): JSValue;
  public
    constructor Create(AOwner: TComponent); override;
    procedure Save(AFileName: string);
    procedure AddNewSheet(ASheetName: string = '');
    procedure RemoveSheet(ASheetName: string);
    procedure Load(AArray: TJSArrayBufferRecord);
    function IsEmptySheet(ASheetName: string): Boolean;
    function ExportToCSV: TJSPromise; //string
    function RowCount: Integer;
    function ColumnCount: Integer;
    function GetCellObjects(AFromCol, AFromRow, AToCol, AToRow: Integer): TXLSXCellArray;
    property ActiveSheet: string read GetActiveSheet write SetActiveSheet;
    property ConvertNumbers: Boolean read FConvertNumbers write FConvertNumbers;
    property Creator: string read GetCreator write SetCreator;
    property LastModifiedBy: string read GetLastModifiedBy write SetLastModifiedBy;
    property SheetNames[Index: Integer]: string read GetSheetName write SetSheetName;
    property SheetNameCount: Integer read GetSheetNameCount;
    property CellAsString[ACol, ARow: Integer]: string read GetCellAsString;
    property CellAsObject[ACol, ARow: Integer]: TXLSXCell read GetCellObject;
  published
    property Grid: TControl read FGrid write SetGrid;
    property GridStartRow: Integer read FGridStartRow write SetGridStartRow;
    property GridStartCol: Integer read FGridStartCol write SetGridStartCol;
    property XlsxStartRow: Integer read FXlsxStartRow write SetXlsxStartRow;
    property XlsxStartCol: Integer read FXlsxStartCol write SetXlsxStartCol;
    property OnWorkbookLoaded: TNotifyEvent read FOnWorkbookLoaded write FOnWorkbookLoaded;
    property OnSheetLoaded: TXLSXSheetLoadedEvent read FOnSheetLoaded write FOnSheetLoaded;
    property OnNewSheetAdded: TXLSXSheetLoadedEvent read FOnNewSheetAdded write FOnNewSheetAdded;
    property OnLoadCell: TXLSXLoadCellEvent read FOnLoadCell write FOnLoadCell;
    property OnSaveCell: TXLSXSaveCellEvent read FOnSaveCell write FOnSaveCell;
  end;

  TWebXLSX = class(TXLSX);

implementation

uses
  WEBLib.Forms, WEBLib.DBCtrls;

{ TXLSX }

procedure TXLSX.AddNewSheet(ASheetName: string);
begin
  if Assigned(FWorkbook) then
  begin
    SaveCurrentSheet;
    if ASheetName <> '' then
      FCurrentSheet := FWorkbook.addWorksheet(ASheetName)
    else
      FCurrentSheet := FWorkbook.addWorksheet;
    LoadCurrentSheet;

    if Assigned(OnNewSheetAdded) then
      OnNewSheetAdded(Self, FCurrentSheet.name);
  end;
end;

constructor TXLSX.Create(AOwner: TComponent);
begin
  inherited;
  FConvertNumbers := True;
  FActiveSheet := '';
  FGridStartRow := 1;
  FGridStartCol := 1;
  FXlsxStartRow := 0;
  FXlsxStartCol := 0;
  InitWorkbook;
end;

function TXLSX.ExportToCSV: TJSPromise;
begin
  Result := TJSPromise.New(
  procedure(AResolve, AReject: TJSPromiseResolver)
	begin
    if Assigned(FWorkbook) then
    begin
      FExportResolve := AResolve;
      FWorkbook.csv.writeBuffer._then(function (AValue: JSValue): JSValue
      var
        dec: TJSTextDecoder;
        strRes: string;
      begin
        dec := TJSTextDecoder.New('utf-8');
        strRes := dec.decode(TJSUint8Array(AValue));
        FExportResolve(strRes);
      end);
    end
    else
      AReject;
	end);
end;

function TXLSX.GetActiveSheet: string;
begin
  Result := FActiveSheet;
end;

function TXLSX.GetCellAsString(ACol, ARow: Integer): string;
begin
  Result := '';
  if Assigned(FCurrentSheet) then
    Result := FCurrentSheet.getCell(ARow, ACol).text;
end;

function TXLSX.GetCellObject(ACol, ARow: Integer): TXLSXCell;
begin
  Result := nil;
  if Assigned(FCurrentSheet) then
    Result := TXLSXCell.Create(FCurrentSheet.getCell(ARow, ACol));
end;

function TXLSX.GetCellObjects(AFromCol, AFromRow, AToCol,
  AToRow: Integer): TXLSXCellArray;
var
  m, n, I: Integer;
begin
  m := (AToCol - AFromCol) + 1;
  n := (AToRow - AFromRow) + 1;

  SetLength(Result, m * n);

  if n > 0 then
  begin
    for I := 0 to Length(Result) - 1 do
    begin
      Result[I] := CellAsObject[(I div n) + AFromCol, (I mod n) + AFromRow];
    end;
  end;
end;

function TXLSX.ColumnCount: Integer;
begin
  Result := 0;
  if Assigned(FCurrentSheet) then
    Result := FCurrentSheet.columnCount;
end;

function TXLSX.GetCreator: string;
begin
  Result := FCreator;
  if Assigned(FWorkbook) then
    Result := FWorkbook.creator;
end;

function TXLSX.GetLastModifiedBy: string;
begin
  Result := FLastModifiedBy;
  if Assigned(FWorkbook) then
    Result := FWorkbook.lastModifiedBy;
end;

function TXLSX.RowCount: Integer;
begin
  Result := 0;
  if Assigned(FCurrentSheet) then
    Result := FCurrentSheet.rowCount;
end;

function TXLSX.GetSheetName(Index: Integer): string;
begin
  if (Index < 0) or (Index >= SheetNameCount) then
    raise Exception.Create('Index out of bounds');

  Result := TJSExcelJSWorksheet(FWorkbook.worksheets[Index]).name;
end;

function TXLSX.GetSheetNameCount: Integer;
begin
  Result := 0;
  if Assigned(FWorkbook) then
    Result := Length(FWorkbook.worksheets);
end;

function TXLSX.HandleWorkbookLoaded(AValue: JSValue): JSValue;
begin
  Result := AValue;
  FWorkbook := TJSExcelJSWorkbook(AValue);

  if Assigned(OnWorkbookLoaded) then
    OnWorkbookLoaded(Self);

  if Length(FWorkbook.worksheets) > 0  then
  begin
    FCurrentSheet := FWorkbook.worksheets[0];
    FActiveSheet := FCurrentSheet.name;
    LoadCurrentSheet;
  end;
end;

procedure TXLSX.InitWorkbook;
begin
  FWorkbook := ExcelJS.Workbook.new;
  FWorkbook.addWorksheet;
  FCurrentSheet := FWorkbook.worksheets[0];
  FActiveSheet := FCurrentSheet.name;
end;

function TXLSX.IsEmptySheet(ASheetName: string): Boolean;
var
  ws: TJSExcelJSWorksheet;
begin
  Result := True;
  ws := FWorkbook.getWorksheet(ASheetName);
  if Assigned(ws) and (ws.actualRowCount > 0) then
    Result := False;
end;

procedure TXLSX.Load(AArray: TJSArrayBufferRecord);
begin
  FActiveSheet := '';
  if Assigned(FWorkbook) then
    FWorkbook.xlsx.load(AArray.jsarraybuffer)._then(@HandleWorkbookLoaded);
end;

procedure TXLSX.LoadCurrentSheet;
var
  eR, eC, I, J: Integer;
  s: string;
  cell: TJSExcelJSCell;
  rec: TJSExcelJSCellRecord;
begin
  if Assigned(FGrid) and Assigned(FCurrentSheet) and (FGrid is TCustomGrid) then
  begin
    TCustomGrid(FGrid).Clear;
    eR := FGridStartRow + FCurrentSheet.rowCount - FXlsxStartRow;
    if eR <= 0 then
      er := 4;
    eC := FGridStartCol + FCurrentSheet.columnCount - FXlsxStartCol;
    if eC <= 0 then
      eC := 4;
    TCustomGridProtected(FGrid).ColCount := eC;
    TCustomGridProtected(FGrid).RowCount := eR;
    if (FCurrentSheet.actualRowCount > 0) then
    begin
      FGrid.BeginUpdate;
      for I := FGridStartRow to eR - 1 do
      begin
        for J := FGridStartCol to eC - 1 do
        begin
          cell := FCurrentSheet.getCell(I - FGridStartRow + FXlsxStartRow + 1, J - FGridStartCol + FXlsxStartCol + 1);
          rec.cell := cell;
          if cell.&type = 4 then
            s := FormatDateTime(TFormatSettings.Invariant.ShortDateFormat, JSDateToDateTime(TJSDate(cell.value)))
          else
            s := cell.text;

          if Assigned(OnLoadCell) then
            OnLoadCell(Self, I, J, s, rec);

          TCustomGrid(FGrid).Cells[J, I] := s;
        end;
      end;
      FGrid.EndUpdate;
    end;
  end;
  if Assigned(OnSheetLoaded) then
    OnSheetLoaded(Self, FCurrentSheet.name);
end;

procedure TXLSX.Notification(AComponent: TComponent; Operation: TOperation);
begin
  inherited;
  if not (csDestroying in ComponentState) then
  begin
    if (Operation = opRemove) then
    begin
      if (AComponent = FGrid) then
        FGrid := nil;
    end;
  end;
end;

procedure TXLSX.RemoveSheet(ASheetName: string);
begin
  if Assigned(FWorkbook) then
    FWorkbook.removeWorksheet(ASheetName);
end;

procedure TXLSX.Save(AFileName: string);
  function HandleWorkbookSave(AValue: JSValue): JSValue;
  begin
    Result := AValue;
    Application.DownloadBinaryFile(TJSUint8Array(AValue), AFileName);
  end;
begin
  SaveCurrentSheet;
  FWorkbook.xlsx.writeBuffer._then(@HandleWorkbookSave);
end;

function Number(S: String): Double; external name 'Number';

procedure TXLSX.SaveCurrentSheet;
var
  I, J: Integer;
  cell: TJSExcelJSCell;
  s: string;
  rec: TJSExcelJSCellRecord;
begin
  if Assigned(FCurrentSheet) and Assigned(FGrid) and (FGrid is TCustomGrid) then
  begin
    for I := FGridStartRow to TCustomGridProtected(FGrid).RowCount - 1 do
    begin
      for J := FGridStartCol to TCustomGridProtected(FGrid).ColCount - 1 do
      begin;
        cell := FCurrentSheet.getCell(I - FGridStartRow + FXlsxStartRow + 1, J - FGridStartCol + FXlsxStartCol + 1);
        s := TCustomGrid(FGrid).Cells[J, I];

        if (not jsIsNaN(s) and (s <> '') and FConvertNumbers) or (cell.&type = 2) then
          cell.value := Number(s)
        else if cell.&type = 4 then
          cell.value := DateTimeToJSDate(StrToDate(s))
        else
          cell.value := s;

        rec.cell := cell;
        if Assigned(OnSaveCell) then
          OnSaveCell(Self, I, J, s, rec);

        cell := rec.cell;
      end;
    end;
  end;
end;

procedure TXLSX.SetActiveSheet(const Value: string);
begin
  FActiveSheet := Value;
  if Assigned(FWorkbook) then
  begin
    SaveCurrentSheet;
    FCurrentSheet := FWorkbook.getWorksheet(FActiveSheet);
    LoadCurrentSheet;
  end;
end;

procedure TXLSX.SetCreator(const Value: string);
begin
  FCreator := Value;
  if Assigned(FWorkbook) then
    FWorkbook.creator := Value;
end;

procedure TXLSX.SetGrid(const Value: TControl);
begin
  if not (Value is TStringGrid) and not (Value is TTableControl) and not (Value is TDBGrid) and not (Value is TDBTableControl) and Assigned(Value) then
    raise Exception.Create('The assigned control is not a TWebStringGrid or a TWebTableControl');

  FGrid := Value;
end;

procedure TXLSX.SetGridStartCol(const Value: Integer);
begin
  if Value >= 0 then
    FGridStartCol := Value
  else
    FGridStartCol := 1;
end;

procedure TXLSX.SetGridStartRow(const Value: Integer);
begin
  if Value >= 0 then
    FGridStartRow := Value
  else
    FGridStartRow := 1;
end;

procedure TXLSX.SetLastModifiedBy(const Value: string);
begin
  FLastModifiedBy := Value;
  if Assigned(FWorkbook) then
    FWorkbook.lastModifiedBy := Value;
end;

procedure TXLSX.SetSheetName(Index: Integer; const Value: string);
begin
  if (Index < 0) or (Index >= SheetNameCount) then
    raise Exception.Create('Index out of bounds');

  if Assigned(FWorkbook) then
    TJSExcelJSWorksheet(FWorkbook.worksheets[Index]).name := Value;
end;

procedure TXLSX.SetXlsxStartCol(const Value: Integer);
begin
  if Value >= 0 then
    FXlsxStartCol := Value
  else
    FXlsxStartCol := 0;
end;

procedure TXLSX.SetXlsxStartRow(const Value: Integer);
begin
  if Value >= 0 then
    FXlsxStartRow := Value
  else
    FXlsxStartRow := 0;
end;

{ TXLSXCell }

procedure TXLSXCell.AddName(AName: string);
begin
  FCell.addName(AName);
end;

constructor TXLSXCell.Create(ACell: TJSExcelJSCell);
begin
  inherited;
  FCell := ACell;
  FFont := TXLSXStyleFont.Create(Self);
  FAlignment := TXLSXStyleAlignment.Create(Self);
  FBorder := TXLSXStyleBorder.Create(Self);
  FProtection := TXLSXStyleProtection.Create(Self);
end;

destructor TXLSXCell.Destroy;
begin
  FFont.Free;
  FAlignment.Free;
  FBorder.Free;
  FProtection.Free;
  inherited;
end;

function TXLSXCell.GetFill: TColor;
var
  c: string;
begin
  Result := clNone;
  if Assigned(FCell) then
  begin
    if Assigned(FCell.fill) and Assigned((FCell.fill as TJSExcelJSFillPattern).&type) then
    begin
      if (FCell.fill as TJSExcelJSFillPattern).&type = 'pattern' then
      begin
        if Assigned((FCell.fill as TJSExcelJSFillPattern).fgColor) and Assigned((FCell.fill as TJSExcelJSFillPattern).fgColor.argb) then
        begin
          c := (FCell.fill as TJSExcelJSFillPattern).fgColor.argb;
          Delete(c, 1, 2);
          Result := HexToColor(c);
        end;
      end;
    end;
  end;
end;

function TXLSXCell.GetNames: TStringDynArray;
begin
  Result := FCell.names;
end;

function TXLSXCell.GetNumericFormat: string;
begin
  Result := FCell.numFmt;
end;

function TXLSXCell.GetText: string;
begin
  Result := FCell.text;
end;

function TXLSXCell.GetType: Integer;
begin
  Result := FCell.&type;
end;

function TXLSXCell.GetValue: JSValue;
begin
  Result := FCell.value;
end;

procedure TXLSXCell.RemoveName(AName: string);
begin
  FCell.removeName(AName);
end;

procedure TXLSXCell.SetAlignment(const Value: TXLSXStyleAlignment);
begin
  FAlignment := Value;
end;

procedure TXLSXCell.SetBorder(const Value: TXLSXStyleBorder);
begin
  FBorder := Value;
end;

procedure TXLSXCell.SetFill(const Value: TColor);
var
  c: TJSExcelJSColor;
  p: TJSExcelJSFillPattern;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.fill) then
    begin
      if (FCell.fill as TJSExcelJSFillPattern).&type = 'pattern' then
      begin
        if Assigned((FCell.fill as TJSExcelJSFillPattern).fgColor) then
          (FCell.fill as TJSExcelJSFillPattern).fgColor.argb := 'FF' + ColorToHex(Value)
        else
        begin
          c := TJSExcelJSColor.new;
          c.argb := 'FF' + ColorToHex(Value);
          (FCell.fill as TJSExcelJSFillPattern).fgColor := c;
        end;
      end;
    end
    else
    begin
      p := TJSExcelJSFillPattern.new;
      c := TJSExcelJSColor.new;
      c.argb := 'FF' + ColorToHex(Value);
      p.fgColor := c;
      FCell.fill := p;
    end;
  end;
end;

procedure TXLSXCell.SetFont(const Value: TXLSXStyleFont);
begin
  FFont := Value;
end;

procedure TXLSXCell.SetNames(const Value: TStringDynArray);
begin
  FCell.names := Value;
end;

procedure TXLSXCell.SetNumericFormat(const Value: string);
begin
  FCell.numFmt := Value;
end;

procedure TXLSXCell.SetProtection(const Value: TXLSXStyleProtection);
begin
  FProtection := Value;
end;

procedure TXLSXCell.SetType(const Value: Integer);
begin
  FCell.&type := Value;
end;

procedure TXLSXCell.SetValue(const Value: JSValue);
begin
  FCell.value := Value;
end;

{ TXLSXStyleFont }

constructor TXLSXStyleFont.Create(ACell: TXLSXCell);
begin
  FCell := ACell.FCell;
end;

function TXLSXStyleFont.GetCharset: Integer;
begin
  Result := 0;
  if Assigned(FCell) and Assigned(FCell.font) and Assigned(FCell.font.charset) then
    Result := FCell.font.charset;
end;

function TXLSXStyleFont.GetColor: TColor;
var
  c: string;
begin
  Result := clNone;
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) and Assigned(FCell.font.color) then
    begin
      if Assigned(FCell.font.color.argb) then
      begin
        c := FCell.font.color.argb;
        Delete(c, 1, 2);
        Result := HexToColor(c);
      end;
    end;
  end;
end;

function TXLSXStyleFont.GetName: string;
begin
  Result := '';
  if Assigned(FCell) and Assigned(FCell.font) and Assigned(FCell.font.name) then
    Result := FCell.font.name;
end;

function TXLSXStyleFont.GetSize: Integer;
begin
  Result := 0;
  if Assigned(FCell) and Assigned(FCell.font) and Assigned(FCell.font.size) then
    Result := FCell.font.size;
end;

function TXLSXStyleFont.GetStyles: TXLSXStyleFontStyles;
begin
  Result := [];
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
    begin
      if FCell.font.bold then
        Result := Result + [xfsBold];

      if FCell.font.italic then
        Result := Result + [xfsItalic];

      if FCell.font.strike then
        Result := Result + [xfsStrike];

      if FCell.font.outline then
        Result := Result + [xfsOutline];
    end;
  end;
end;

function TXLSXStyleFont.GetTheme: Integer;
begin
  Result := 0;
  if Assigned(FCell) and Assigned(FCell.font) and Assigned(FCell.font.color) then
  begin
    if Assigned(FCell.font.color.theme) then
      Result := FCell.font.color.theme;
  end;
end;

function TXLSXStyleFont.GetUnderline: TXLSXStyleFontUnderline;
var
  s: string;
begin
  Result := xfuNone;
  if Assigned(FCell) and Assigned(FCell.font) and Assigned(FCell.font.underline) then
  begin
    if Js.isBoolean(FCell.font.underline) then
    begin
      if JS.toBoolean(FCell.font.Underline) then
        Result := xfuSingle
      else
        Result := xfuNone;
    end
    else if Js.isString(FCell.font.underline) then
    begin
      s := Js.toString(FCell.font.underline);
      if s = 'none' then
        Result := xfuNone
      else if s = 'single' then
        Result := xfuSingle
      else if s = 'double' then
        Result := xfuDouble
      else if s = 'singleAccounting' then
        Result := xfuSingleAccounting
      else if s = 'doubleAccounting' then
        Result := xfuDoubleAccounting;
    end;
  end;
end;

function TXLSXStyleFont.GetVertAlign: TXLSXStyleFontAlign;
begin
  Result := xfaDefault;
  if Assigned(FCell) and Assigned(FCell.font) and Assigned(FCell.font.vertAlign) then
  begin
    if FCell.font.vertAlign = 'superscript' then
      Result := xfaSuperscript
    else if FCell.font.vertAlign = 'subscript' then
      Result := xfaSubscript;
  end;
end;

procedure TXLSXStyleFont.SetCharset(const Value: Integer);
var
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
      FCell.font.charset := Value
    else
    begin
      f := TJSExcelJSFont.new;
      f.charset := Value;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetColor(const Value: TColor);
var
  c: TJSExcelJSColor;
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
    begin
      if Assigned(FCell.font.color) then
        FCell.font.color.argb := 'FF' + ColorToHex(Value)
      else
      begin
        c := TJSExcelJSColor.new;
        c.argb := 'FF' + ColorToHex(Value);
        FCell.font.color := c;
      end;
    end
    else
    begin
      f := TJSExcelJSFont.new;
      c := TJSExcelJSColor.new;
      c.argb := 'FF' + ColorToHex(Value);
      f.color := c;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetName(const Value: string);
var
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
      FCell.font.name := Value
    else
    begin
      f := TJSExcelJSFont.new;
      f.name := Value;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetSize(const Value: Integer);
var
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
      FCell.font.size := Value
    else
    begin
      f := TJSExcelJSFont.new;
      f.size := Value;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetStyles(const Value: TXLSXStyleFontStyles);
var
  f: TJSExcelJSFont;
  s: TXLSXStyleFontStyle;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
    begin
      FCell.font.bold := False;
      FCell.font.italic := False;
      FCell.font.strike := False;
      FCell.font.outline := False;

      for s in Value do
      begin
        if s = xfsBold then
          FCell.font.bold := True
        else if s = xfsItalic then
          FCell.font.italic := True
        else if s = xfsStrike then
          FCell.font.strike := True
        else if s = xfsOutline then
          FCell.font.outline := True
      end;
    end
    else
    begin
      f := TJSExcelJSFont.new;
      for s in Value do
      begin
        if s = xfsBold then
          f.bold := True
        else if s = xfsItalic then
          f.italic := True
        else if s = xfsStrike then
          f.strike := True
        else if s = xfsOutline then
          f.outline := True
      end;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetTheme(const Value: Integer);
var
  c: TJSExcelJSColor;
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
    begin
      if Assigned(FCell.font.color) then
        FCell.font.color.theme := Value
      else
      begin
        c := TJSExcelJSColor.new;
        c.theme := Value;
        FCell.font.color := c;
      end;
    end
    else
    begin
      f := TJSExcelJSFont.new;
      c := TJSExcelJSColor.new;
      c.theme := Value;
      f.color := c;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetUnderline(const Value: TXLSXStyleFontUnderline);
var
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
    begin
      case Value of
        xfuNone: FCell.font.underline := 'none';
        xfuSingle: FCell.font.underline := 'single';
        xfuDouble: FCell.font.underline := 'double';
        xfuSingleAccounting: FCell.font.underline := 'singleAccounting';
        xfuDoubleAccounting: FCell.font.underline := 'doubleAccounting';
      end;
    end
    else
    begin
      f := TJSExcelJSFont.new;
      case Value of
        xfuNone: f.underline := 'none';
        xfuSingle: f.underline := 'single';
        xfuDouble: f.underline := 'double';
        xfuSingleAccounting: f.underline := 'singleAccounting';
        xfuDoubleAccounting: f.underline := 'doubleAccounting';
      end;
      FCell.font := f;
    end;
  end;
end;

procedure TXLSXStyleFont.SetVertAlign(const Value: TXLSXStyleFontAlign);
var
  f: TJSExcelJSFont;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.font) then
    begin
      case Value of
        xfaDefault: FCell.font.vertAlign := '';
        xfaSuperscript: FCell.font.vertAlign := 'superscript';
        xfaSubscript: FCell.font.vertAlign := 'subscript';
      end;
    end
    else
    begin
      f := TJSExcelJSFont.new;
      case Value of
        xfaSuperscript: f.vertAlign := 'superscript';
        xfaSubscript: f.vertAlign := 'subscript';
      end;
      FCell.font := f;
    end;
  end;
end;

{ TXLSXStyleAlignment }

constructor TXLSXStyleAlignment.Create(ACell: TXLSXCell);
begin
  FCell := ACell.FCell;
end;

function TXLSXStyleAlignment.GetHorizontal: TXLSXStyleHorizontalAlignment;
begin
  Result := xhaLeft;
  if Assigned(FCell) and Assigned(FCell.alignment) and Assigned(FCell.alignment.horizontal) then
  begin
    if FCell.alignment.horizontal = 'center' then
      Result := xhaCenter
    else
    if FCell.alignment.horizontal = 'left' then
      Result := xhaLeft
    else if FCell.alignment.horizontal = 'right' then
      Result := xhaRight
    else if FCell.alignment.horizontal = 'distributed' then
      Result := xhaDistributed
    else if FCell.alignment.horizontal = 'justify' then
      Result := xhaJustify;
  end;
end;

function TXLSXStyleAlignment.GetIndent: Integer;
begin
  Result := 0;
  if Assigned(FCell) and Assigned(FCell.alignment) and Assigned(FCell.alignment.indent) then
    Result := FCell.alignment.indent;
end;

function TXLSXStyleAlignment.GetReadingOrder: TXLSXStyleReadingOrder;
begin
  Result := xroRTL;
  if Assigned(FCell) and Assigned(FCell.alignment) and Assigned(FCell.alignment.readingOrder) then
  begin
    if FCell.alignment.readingOrder = 'ltr' then
      Result := xroLTR;
  end;
end;

function TXLSXStyleAlignment.GetShrinkTofIt: Boolean;
begin
  Result := False;
  if Assigned(FCell) and Assigned(FCell.alignment) then
  begin
    if FCell.alignment.shrinkToFit then
      Result := True;
  end;
end;

function TXLSXStyleAlignment.GetTextRotation: Integer;
begin
  Result := 0;
  if Assigned(FCell) and Assigned(FCell.alignment) and Assigned(FCell.alignment.textRotation) then
  begin
    if IsInteger(FCell.alignment.textRotation) then
      Result := ToInteger(FCell.alignment.textRotation);
  end;
end;

function TXLSXStyleAlignment.GetVertical: TXLSXStyleVerticalAlignment;
begin
  Result := xvaMiddle;
  if Assigned(FCell) and Assigned(FCell.alignment) and Assigned(FCell.alignment.vertical) then
  begin
    if FCell.alignment.vertical = 'top' then
      Result := xvaTop
    else if FCell.alignment.vertical = 'bottom' then
      Result := xvaBottom
    else if FCell.alignment.vertical = 'distributed' then
      Result := xvaDistributed
    else if FCell.alignment.vertical = 'justify' then
      Result := xvaJustify;
  end;
end;

function TXLSXStyleAlignment.GetVerticalText: Boolean;
begin
  Result := False;
  if Assigned(FCell) and Assigned(FCell.alignment) and Assigned(FCell.alignment.textRotation) then
  begin
    if IsString(FCell.alignment.textRotation) then
      Result := True;
  end;
end;

function TXLSXStyleAlignment.GetWrapText: Boolean;
begin
  Result := False;
  if Assigned(FCell) and Assigned(FCell.alignment) then
  begin
    if FCell.alignment.wrapText then
      Result := True;
  end;
end;

procedure TXLSXStyleAlignment.SetHorizontal(
  const Value: TXLSXStyleHorizontalAlignment);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
    begin
      case Value of
        xhaLeft: FCell.alignment.horizontal := 'left';
        xhaCenter: FCell.alignment.horizontal := 'center';
        xhaRight: FCell.alignment.horizontal := 'right';
        xhaFill: FCell.alignment.horizontal := 'fill';
        xhaJustify: FCell.alignment.horizontal := 'justify';
        xhaCenterCont: FCell.alignment.horizontal := 'centerContinuous';
        xhaDistributed: FCell.alignment.horizontal := 'distributed';
      end;
    end
    else
    begin
      a := TJSExcelJSAlignment.new;
      case Value of
        xhaLeft: a.horizontal := 'left';
        xhaCenter: a.horizontal := 'center';
        xhaRight: a.horizontal := 'right';
        xhaFill: a.horizontal := 'fill';
        xhaJustify: a.horizontal := 'justify';
        xhaCenterCont: a.horizontal := 'centerContinuous';
        xhaDistributed: a.horizontal := 'distributed';
      end;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetIndent(const Value: Integer);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
      FCell.alignment.indent := Value
    else
    begin
      a := TJSExcelJSAlignment.new;
      a.indent := Value;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetReadingOrder(
  const Value: TXLSXStyleReadingOrder);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
    begin
      case Value of
        xroRTL: FCell.alignment.readingOrder := 'rtl';
        xroLTR: FCell.alignment.readingOrder := 'ltr';
      end;
    end
    else
    begin
      a := TJSExcelJSAlignment.new;
      case Value of
        xroRTL: a.readingOrder := 'rtl';
        xroLTR: a.readingOrder := 'ltr';
      end;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetShrinkToFit(const Value: Boolean);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
      FCell.alignment.shrinkToFit := Value
    else
    begin
      a := TJSExcelJSAlignment.new;
      a.shrinkToFit := Value;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetTextRotation(const Value: Integer);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
      FCell.alignment.textRotation := Value
    else
    begin
      a := TJSExcelJSAlignment.new;
      a.textRotation := Value;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetVertical(
  const Value: TXLSXStyleVerticalAlignment);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
    begin
      case Value of
        xvaTop: FCell.alignment.vertical := 'top';
        xvaMiddle: FCell.alignment.vertical := 'middle';
        xvaBottom: FCell.alignment.vertical := 'bottom';
        xvaDistributed: FCell.alignment.vertical := 'distributed';
        xvaJustify: FCell.alignment.vertical := 'justify';
      end;
    end
    else
    begin
      a := TJSExcelJSAlignment.new;
      case Value of
        xvaTop: a.vertical := 'top';
        xvaMiddle: a.vertical := 'middle';
        xvaBottom: a.vertical := 'bottom';
        xvaDistributed: a.vertical := 'distributed';
        xvaJustify: a.vertical := 'justify';
      end;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetVerticalText(const Value: Boolean);
var
  a: TJSExcelJSAlignment;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
    begin
      if Value then
        FCell.alignment.textRotation := 'vertical'
      else
        FCell.alignment.textRotation := 0;
    end
    else
    begin
      a := TJSExcelJSAlignment.new;
      if Value then
        a.textRotation := 'vertical'
      else
        a.textRotation := 0;
      FCell.alignment := a;
    end;
  end;
end;

procedure TXLSXStyleAlignment.SetWrapText(const Value: Boolean);
var
  a: TJSExcelJSAlignment;
begin
    if Assigned(FCell) then
  begin
    if Assigned(FCell.alignment) then
      FCell.alignment.wrapText := Value
    else
    begin
      a := TJSExcelJSAlignment.new;
      a.wrapText := Value;
      FCell.alignment := a;
    end;
  end;
end;

{ TXLSXStyleBorderBase }

constructor TXLSXStyleBorderBase.Create(AOwner: TXLSXStyleBorder);
begin
  FOwner := AOwner;
end;

function TXLSXStyleBorderBase.GetColor: TColor;
  function GetBorderColor(ABorder: TJSExcelJSBorder): TColor;
  var
    c: string;
  begin
    Result := clNone;
    if Assigned(ABorder) and Assigned(ABorder.color) and Assigned(ABorder.color.argb) then
    begin
      c := ABorder.color.argb;
      Delete(c, 1, 2);
      Result := HexToColor(c);
    end;
  end;
begin
  Result := clNone;
  if Assigned(FOwner.FCell) and Assigned(FOwner.FCell.border) then
  begin
    case FBorder of
      btTop: Result := GetBorderColor(FOwner.FCell.border.top);
      btLeft: Result := GetBorderColor(FOwner.FCell.border.left);
      btBottom: Result := GetBorderColor(FOwner.FCell.border.bottom);
      btRight: Result := GetBorderColor(FOwner.FCell.border.right);
      btDiagonal: Result := GetBorderColor(FOwner.FCell.border.diagonal);
    end;
  end;
end;

function TXLSXStyleBorderBase.GetStyle: TXLSXStyleBorderStyle;
  function GetBorderStyle(ABorder: TJSExcelJSBorder): TXLSXStyleBorderStyle;
  begin
    Result := xbsNone;
    if Assigned(ABorder) and Assigned(ABorder.style) then
    begin
      if ABorder.style = 'thin' then
        Result := xbsThin
      else if ABorder.style = 'dotted' then
        Result := xbsDotted
      else if ABorder.style = 'dashDot' then
        Result := xbsDashDot
      else if ABorder.style = 'hair' then
        Result := xbsHair
      else if ABorder.style = 'dashDotDot' then
        Result := xbsDashDotDot
      else if ABorder.style = 'slantDashDot' then
        Result := xbsSlantDashDot
      else if ABorder.style = 'mediumDashed' then
        Result := xbsMediumDashed
      else if ABorder.style = 'mediumDashDotDot' then
        Result := xbsMediumDashDotDot
      else if ABorder.style = 'mediumDashDot' then
        Result := xbsMediumDashDot
      else if ABorder.style = 'medium' then
        Result := xbsMedium
      else if ABorder.style = 'double' then
        Result := xbsDouble
      else if ABorder.style = 'thick' then
        Result := xbsThick;
    end;
  end;
begin
  Result := xbsNone;
  if Assigned(FOwner.FCell) and Assigned(FOwner.FCell.border) then
  begin
    case FBorder of
      btTop: Result := GetBorderStyle(FOwner.FCell.border.top);
      btLeft: Result := GetBorderStyle(FOwner.FCell.border.left);
      btBottom: Result := GetBorderStyle(FOwner.FCell.border.bottom);
      btRight: Result := GetBorderStyle(FOwner.FCell.border.right);
      btDiagonal: Result := GetBorderStyle(FOwner.FCell.border.diagonal);
    end;
  end;
end;

function TXLSXStyleBorderBase.GetTheme: Integer;
  function GetBorderTheme(ABorder: TJSExcelJSBorder): TColor;
  var
  begin
    Result := 0;
    if Assigned(ABorder) and Assigned(ABorder.color) and Assigned(ABorder.color.theme) then
      Result := ABorder.color.theme;
  end;
begin
  Result := 0;
  if Assigned(FOwner.FCell) and Assigned(FOwner.FCell.border) then
  begin
    case FBorder of
      btTop: Result := GetBorderTheme(FOwner.FCell.border.top);
      btLeft: Result := GetBorderTheme(FOwner.FCell.border.left);
      btBottom: Result := GetBorderTheme(FOwner.FCell.border.bottom);
      btRight: Result := GetBorderTheme(FOwner.FCell.border.right);
      btDiagonal: Result := GetBorderTheme(FOwner.FCell.border.diagonal);
    end;
  end;
end;

procedure TXLSXStyleBorderBase.SetColor(const Value: TColor);
  procedure SetBorderColor(var ABorder: TJSExcelJSBorders;
    ABorderType: TXLSXStyleBorderType; AColor: TColor);
  var
    b: TJSExcelJSBorder;
    c: TJSExcelJSColor;
  begin
    case ABorderType of
      btTop: b := ABorder.top;
      btLeft: b := ABorder.left;
      btBottom: b := ABorder.bottom;
      btRight: b := ABorder.right;
      btDiagonal: b := ABorder.diagonal;
    end;

    if Assigned(b) then
    begin
      if Assigned(b.color) then
        b.color.argb := 'FF' + ColorToHex(AColor)
      else
      begin
        c := TJSExcelJSColor.new;
        c.argb := 'FF' + ColorToHex(AColor);
        b.color := c;
      end;
    end
    else
    begin
      b := TJSExcelJSBorder.new;
      c := TJSExcelJSColor.new;
      c.argb := 'FF' + ColorToHex(AColor);
      b.color := c;
    end;

    case ABorderType of
      btTop: ABorder.top := b;
      btLeft: ABorder.left := b;
      btBottom: ABorder.bottom := b;
      btRight: ABorder.right := b;
      btDiagonal: ABorder.diagonal := TJSExcelJSBorderDiagonal(b);
    end;
  end;
var
  bord: TJSExcelJSBorders;
begin
  if Assigned(FOwner.FCell) then
  begin
    if Assigned(FOwner.FCell.border) then
      SetBorderColor(FOwner.FCell.border, FBorder, Value)
    else
    begin
      bord := TJSExcelJSBorders.new;
      SetBorderColor(bord, FBorder, Value);
      FOwner.FCell.border := bord;
    end;
  end;
end;

procedure TXLSXStyleBorderBase.SetStyle(const Value: TXLSXStyleBorderStyle);
  function GetStyleAsString(AStyle: TXLSXStyleBorderStyle): string;
  begin
    Result := '';
    case AStyle of
      xbsThin: Result := 'thin';
      xbsDotted: Result := 'dotted';
      xbsDashDot: Result := 'dashDot';
      xbsHair: Result := 'hair';
      xbsDashDotDot: Result := 'dashDotDot';
      xbsSlantDashDot: Result := 'slantDashDot';
      xbsMediumDashed: Result := 'mediumDashed';
      xbsMediumDashDotDot: Result := 'mediumDashDotDot';
      xbsMediumDashDot: Result := 'mediumDashDot';
      xbsMedium: Result := 'medium';
      xbsDouble: Result := 'double';
      xbsThick: Result := 'thick';
    end;
  end;

  procedure SetBorderStyle(var ABorder: TJSExcelJSBorders;
    ABorderType: TXLSXStyleBorderType; AStyle: TXLSXStyleBorderStyle);
  var
    b: TJSExcelJSBorder;
  begin
    case ABorderType of
      btTop: b := ABorder.top;
      btLeft: b := ABorder.left;
      btBottom: b := ABorder.bottom;
      btRight: b := ABorder.right;
      btDiagonal: b := ABorder.diagonal;
    end;

    if Assigned(b) then
      b.style := GetStyleAsString(AStyle)
    else
    begin
      b := TJSExcelJSBorder.new;
      b.style := GetStyleAsString(AStyle)
    end;

    case ABorderType of
      btTop: ABorder.top := b;
      btLeft: ABorder.left := b;
      btBottom: ABorder.bottom := b;
      btRight: ABorder.right := b;
      btDiagonal: ABorder.diagonal := TJSExcelJSBorderDiagonal(b);
    end;
  end;
var
  bord: TJSExcelJSBorders;
begin
  if Assigned(FOwner.FCell) then
  begin
    if Assigned(FOwner.FCell.border) then
      SetBorderStyle(FOwner.FCell.border, FBorder, Value)
    else
    begin
      bord := TJSExcelJSBorders.new;
      SetBorderStyle(bord, FBorder, Value);
      FOwner.FCell.border := bord;
    end;
  end;
end;

procedure TXLSXStyleBorderBase.SetTheme(const Value: Integer);
  procedure SetBorderTheme(var ABorder: TJSExcelJSBorders;
    ABorderType: TXLSXStyleBorderType; ATheme: Integer);
  var
    b: TJSExcelJSBorder;
    c: TJSExcelJSColor;
  begin
    case ABorderType of
      btTop: b := ABorder.top;
      btLeft: b := ABorder.left;
      btBottom: b := ABorder.bottom;
      btRight: b := ABorder.right;
      btDiagonal: b := ABorder.diagonal;
    end;

    if Assigned(b) then
    begin
      if Assigned(b.color) then
        b.color.theme := ATheme
      else
      begin
        c := TJSExcelJSColor.new;
        c.theme := ATheme;
        b.color := c;
      end;
    end
    else
    begin
      b := TJSExcelJSBorder.new;
      c := TJSExcelJSColor.new;
      c.theme := ATheme;
      b.color := c;
    end;

    case ABorderType of
      btTop: ABorder.top := b;
      btLeft: ABorder.left := b;
      btBottom: ABorder.bottom := b;
      btRight: ABorder.right := b;
      btDiagonal: ABorder.diagonal := TJSExcelJSBorderDiagonal(b);
    end;
  end;
var
  bord: TJSExcelJSBorders;
begin
  if Assigned(FOwner.FCell) then
  begin
    if Assigned(FOwner.FCell.border) then
      SetBorderTheme(FOwner.FCell.border, FBorder, Value)
    else
    begin
      bord := TJSExcelJSBorders.new;
      SetBorderTheme(bord, FBorder, Value);
      FOwner.FCell.border := bord;
    end;
  end;
end;

{ TXLSXStyleBorderDiagonal }

function TXLSXStyleBorderDiagonal.GetDown: Boolean;
begin
  Result := False;
  if Assigned(FOwner.FCell) and Assigned(FOwner.FCell.border) and Assigned(FOwner.FCell.border.diagonal) then
  begin
    if (FOwner.FCell.border.diagonal.down) then
      Result := FOwner.FCell.border.diagonal.down;
  end;
end;

function TXLSXStyleBorderDiagonal.GetUp: Boolean;
begin
  Result := False;
  if Assigned(FOwner.FCell) and Assigned(FOwner.FCell.border) and Assigned(FOwner.FCell.border.diagonal) then
  begin
    if (FOwner.FCell.border.diagonal.up) then
      Result := FOwner.FCell.border.diagonal.up;
  end;
end;

procedure TXLSXStyleBorderDiagonal.SetDown(const Value: Boolean);
  procedure SetBorderDown(var ABorder: TJSExcelJSBorders; ADown: Boolean);
  var
    b: TJSExcelJSBorderDiagonal;
  begin
    b := ABorder.diagonal;

    if Assigned(b) then
      b.down := ADown
    else
    begin
      b := TJSExcelJSBorderDiagonal.new;
      b.down := ADown;
    end;

    ABorder.diagonal := b;
  end;
var
  bord: TJSExcelJSBorders;
begin
  if Assigned(FOwner.FCell) then
  begin
    if Assigned(FOwner.FCell.border) then
      SetBorderDown(FOwner.FCell.border, Value)
    else
    begin
      bord := TJSExcelJSBorders.new;
      SetBorderDown(bord, Value);
      FOwner.FCell.border := bord;
    end;
  end;
end;

procedure TXLSXStyleBorderDiagonal.SetUp(const Value: Boolean);
  procedure SetBorderUp(var ABorder: TJSExcelJSBorders; AUp: Boolean);
  var
    b: TJSExcelJSBorderDiagonal;
  begin
    b := ABorder.diagonal;

    if Assigned(b) then
      b.up := AUp
    else
    begin
      b := TJSExcelJSBorderDiagonal.new;
      b.up := AUp;
    end;

    ABorder.diagonal := b;
  end;
var
  bord: TJSExcelJSBorders;
begin
  if Assigned(FOwner.FCell) then
  begin
    if Assigned(FOwner.FCell.border) then
      SetBorderUp(FOwner.FCell.border, Value)
    else
    begin
      bord := TJSExcelJSBorders.new;
      SetBorderUp(bord, Value);
      FOwner.FCell.border := bord;
    end;
  end;
end;

{ TXLSXStyleBorder }

constructor TXLSXStyleBorder.Create(ACell: TXLSXCell);
begin
  inherited;
  FCell := ACell.FCell;
  FBottom := TXLSXStyleBorderBase.Create(Self);
  FBottom.FBorder := btBottom;
  FDiagonal := TXLSXStyleBorderDiagonal.Create(Self);
  FDiagonal.FBorder := btDiagonal;
  FLeft := TXLSXStyleBorderBase.Create(Self);
  FLeft.FBorder := btLeft;
  FRight := TXLSXStyleBorderBase.Create(Self);
  FRight.FBorder := btRight;
  FTop := TXLSXStyleBorderBase.Create(Self);
  FTop.FBorder := btTop;
end;

destructor TXLSXStyleBorder.Destroy;
begin
  FBottom.Free;
  FDiagonal.Free;
  FLeft.Free;
  FRight.Free;
  FTop.Free;
  inherited;
end;

procedure TXLSXStyleBorder.SetBottom(const Value: TXLSXStyleBorderBase);
begin
  FBottom := Value;
end;

procedure TXLSXStyleBorder.SetDiagonal(const Value: TXLSXStyleBorderDiagonal);
begin
  FDiagonal := Value;
end;

procedure TXLSXStyleBorder.SetLeft(const Value: TXLSXStyleBorderBase);
begin
  FLeft := Value;
end;

procedure TXLSXStyleBorder.SetRight(const Value: TXLSXStyleBorderBase);
begin
  FRight := Value;
end;

procedure TXLSXStyleBorder.SetTop(const Value: TXLSXStyleBorderBase);
begin
  FTop := Value;
end;

{ TXLSXStyleProtection }

constructor TXLSXStyleProtection.Create(ACell: TXLSXCell);
begin
  FCell := ACell.FCell;
end;

function TXLSXStyleProtection.GetHidden: Boolean;
begin
  Result := False;
  if Assigned(FCell) and Assigned(FCell.protection) then
  begin
    if FCell.protection.hidden then
      Result := FCell.protection.hidden;
  end;
end;

function TXLSXStyleProtection.GetLocked: Boolean;
begin
  Result := False;
  if Assigned(FCell) and Assigned(FCell.protection) then
  begin
    if FCell.protection.locked then
      Result := FCell.protection.locked;
  end;
end;

procedure TXLSXStyleProtection.SetHidden(const Value: Boolean);
var
  p: TJSExcelJSProtection;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.protection) then
      FCell.protection.hidden := Value
    else
    begin
      p := TJSExcelJSProtection.new;
      p.hidden := Value;
      FCell.protection := p;
    end;
  end;
end;

procedure TXLSXStyleProtection.SetLocked(const Value: Boolean);
var
  p: TJSExcelJSProtection;
begin
  if Assigned(FCell) then
  begin
    if Assigned(FCell.protection) then
      FCell.protection.locked := Value
    else
    begin
      p := TJSExcelJSProtection.new;
      p.locked := Value;
      FCell.protection := p;
    end;
  end;
end;

end.
