unit uMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Mask, WEBLib.Mask;

type
  TfmMain = class(TForm)
    WebPanel1: TPanel;
    WebImageControl1: TImageControl;
    WebLabel4: TLabel;
    WebLabel1: TLabel;
    btnLogin: TButton;
    edUsername: TEdit;
    edPassword: TMaskEdit;
    lbFelinloggningText: TLabel;
    WebTimer1: TTimer;
    WebTimerStartXdataConnection: TTimer;
    lbXdataConnected: TLabel;
    procedure btnLoginClick(Sender: TObject);
    procedure WebTimerStartXdataConnectionTimer(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  fmMain: TfmMain;

implementation

{$R *.dfm}

uses uDataModule, uData1;


procedure TfmMain.btnLoginClick(Sender: TObject);
//brief: opens another form with preferred method
var
  newform:TfmData1;

  procedure AfterShowModal(AValue:TModalResult);
  begin
    document.location.reload(true);  //reload webpage. login and password edit boxes are cleared
  end;

  //async called OnCreate for TForm2
  procedure AfterCreate(AForm:TObject);
  begin
    if (edUserName.text ='ambra') then (AForm as TfmData1).setCustomer:=1107;
    if (edUserName.text ='lundscience') then (AForm as TfmData1).setCustomer:=1104;
  end;

begin
  if ((edUserName.text ='ambra') and (edPassword.text = 'delta')) or
     ((edUserName.text ='lundscience') and (edPassword.text = 'service')) or
     ((edUserName.text ='lundscience') and (edPassword.text = 'RIIM'))
  then begin
    lbFelinloggningtext.visible:=false;
    if (edUserName.text ='ambra') and (edPassword.text = 'delta') then begin
      newform:=TfmData1.CreateNew(@AfterCreate);
      newform.ShowModal(@AfterShowModal);
    end;
  end else begin
    lbFelinloggningtext.visible:=true;
    WebTimer1.Enabled:=true;
  end;
end;





procedure TfmMain.WebTimer1Timer(Sender: TObject);
begin
  lbFelinloggningtext.visible:=false;
end;

procedure TfmMain.WebTimerStartXdataConnectionTimer(Sender: TObject);
begin
  WebTimerStartXdataConnection.enabled:=false;
  fmDataModule.ConnectXdataServer;
end;

procedure TfmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbFelinloggningText := TLabel.Create(Self);
  lbXdataConnected := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  btnLogin := TButton.Create(Self);
  edUsername := TEdit.Create(Self);
  edPassword := TMaskEdit.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  WebTimerStartXdataConnection := TTimer.Create(Self);

  lbFelinloggningText.BeforeLoadDFMValues;
  lbXdataConnected.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  edUsername.BeforeLoadDFMValues;
  edPassword.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  WebTimerStartXdataConnection.BeforeLoadDFMValues;
  try
    Name := 'fmMain';
    Width := 1099;
    Height := 825;
    Color := clBurlywood;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    lbFelinloggningText.SetParentComponent(Self);
    lbFelinloggningText.Name := 'lbFelinloggningText';
    lbFelinloggningText.Left := 368;
    lbFelinloggningText.Top := 568;
    lbFelinloggningText.Width := 270;
    lbFelinloggningText.Height := 28;
    lbFelinloggningText.Caption := 'Fel inloggning. F'#246'rs'#246'k igen';
    lbFelinloggningText.Color := clBurlywood;
    lbFelinloggningText.ElementLabelClassName := 'form-check-label';
    lbFelinloggningText.Font.Charset := DEFAULT_CHARSET;
    lbFelinloggningText.Font.Color := clWindowText;
    lbFelinloggningText.Font.Height := -23;
    lbFelinloggningText.Font.Name := 'Tahoma';
    lbFelinloggningText.Font.Style := [];
    lbFelinloggningText.HeightStyle := ssAuto;
    lbFelinloggningText.HeightPercent := 100.000000000000000000;
    lbFelinloggningText.ParentFont := False;
    lbFelinloggningText.Visible := False;
    lbFelinloggningText.WidthPercent := 100.000000000000000000;
    lbXdataConnected.SetParentComponent(Self);
    lbXdataConnected.Name := 'lbXdataConnected';
    lbXdataConnected.Left := 419;
    lbXdataConnected.Top := 720;
    lbXdataConnected.Width := 115;
    lbXdataConnected.Height := 13;
    lbXdataConnected.Caption := 'Xdata server connected';
    lbXdataConnected.Color := clBurlywood;
    lbXdataConnected.ElementClassName := 'custom-control custom-label';
    lbXdataConnected.ElementLabelClassName := 'form-check-label';
    lbXdataConnected.ElementFont := efCSS;
    lbXdataConnected.HeightStyle := ssAuto;
    lbXdataConnected.HeightPercent := 100.000000000000000000;
    lbXdataConnected.Visible := False;
    lbXdataConnected.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1099;
    WebPanel1.Height := 65;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.Align := alTop;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 22;
    WebPanel1.Color := clBlanchedalmond;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebPanel1.ShowCaption := False;
    WebPanel1.TabOrder := 0;
    WebImageControl1.SetParentComponent(WebPanel1);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 3;
    WebImageControl1.Top := 4;
    WebImageControl1.Width := 57;
    WebImageControl1.Height := 54;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 18;
    WebImageControl1.Picture.LoadFromFile('uMain.WebPanel1.WebImageControl1.Picture.png');
    WebLabel4.SetParentComponent(WebPanel1);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 157;
    WebLabel4.Top := 42;
    WebLabel4.Width := 257;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Lund Science AB,  2024 ver 1.12_1 bootstrap';
    WebLabel4.Color := clBurlywood;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clBlack;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 90;
    WebLabel1.Top := 1;
    WebLabel1.Width := 377;
    WebLabel1.Height := 35;
    WebLabel1.Caption := 'Lund Science IMD Web portal';
    WebLabel1.Color := clBurlywood;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -29;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 389;
    btnLogin.Top := 464;
    btnLogin.Width := 185;
    btnLogin.Height := 57;
    btnLogin.Caption := 'Logga in';
    btnLogin.ChildOrder := 1;
    btnLogin.ElementClassName := 'btn btn-success btn-lg';
    btnLogin.ElementFont := efCSS;
    btnLogin.Font.Charset := DEFAULT_CHARSET;
    btnLogin.Font.Color := clWindowText;
    btnLogin.Font.Height := -19;
    btnLogin.Font.Name := 'Tahoma';
    btnLogin.Font.Style := [];
    btnLogin.HeightStyle := ssAuto;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.ParentFont := False;
    btnLogin.WidthStyle := ssAuto;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
    edUsername.SetParentComponent(Self);
    edUsername.Name := 'edUsername';
    edUsername.Left := 368;
    edUsername.Top := 312;
    edUsername.Width := 225;
    edUsername.Height := 41;
    edUsername.ChildOrder := 2;
    edUsername.ElementClassName := 'form-control';
    edUsername.ElementFont := efCSS;
    edUsername.Font.Charset := DEFAULT_CHARSET;
    edUsername.Font.Color := clWindowText;
    edUsername.Font.Height := -19;
    edUsername.Font.Name := 'Tahoma';
    edUsername.Font.Style := [];
    edUsername.HeightStyle := ssAuto;
    edUsername.HeightPercent := 100.000000000000000000;
    edUsername.ParentFont := False;
    edUsername.WidthPercent := 100.000000000000000000;
    edPassword.SetParentComponent(Self);
    edPassword.Name := 'edPassword';
    edPassword.Left := 368;
    edPassword.Top := 376;
    edPassword.Width := 225;
    edPassword.Height := 41;
    edPassword.Font.Charset := DEFAULT_CHARSET;
    edPassword.Font.Color := clWindowText;
    edPassword.Font.Height := -19;
    edPassword.Font.Name := 'Tahoma';
    edPassword.Font.Style := [];
    edPassword.ParentFont := False;
    edPassword.PasswordChar := '*';
    edPassword.TabOrder := 3;
    edPassword.Text := '';
    edPassword.ElementClassName := 'form-control';
    edPassword.ElementFont := efCSS;
    edPassword.HeightStyle := ssAuto;
    edPassword.HeightPercent := 100.000000000000000000;
    edPassword.WidthPercent := 100.000000000000000000;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 5000;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 628;
    WebTimer1.Top := 104;
    WebTimerStartXdataConnection.SetParentComponent(Self);
    WebTimerStartXdataConnection.Name := 'WebTimerStartXdataConnection';
    SetEvent(WebTimerStartXdataConnection, Self, 'OnTimer', 'WebTimerStartXdataConnectionTimer');
    WebTimerStartXdataConnection.Left := 836;
    WebTimerStartXdataConnection.Top := 104;
  finally
    lbFelinloggningText.AfterLoadDFMValues;
    lbXdataConnected.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    edUsername.AfterLoadDFMValues;
    edPassword.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    WebTimerStartXdataConnection.AfterLoadDFMValues;
  end;
end;

end.