unit uDataModule;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, XData.Web.Client,
  XData.Web.Connection;

type
  TfmDataModule = class(TDataModule)
    XDataWebConnection1: TXDataWebConnection;
    XDataWebClient1: TXDataWebClient;
    procedure XDataWebConnection1Connect(Sender: TObject);
    procedure XDataWebConnection1Error(Error: TXDataWebConnectionError);
  private
    { Private declarations }
  public
    { Public declarations }
    procedure ConnectXdataServer;
  protected procedure LoadDFMValues; override; end;

var
  fmDataModule: TfmDataModule;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

uses uMain;



procedure TfmDataModule.ConnectXdataServer;
begin
  XDataWebConnection1.Connected:=true;
end;

procedure TfmDataModule.XDataWebConnection1Connect(Sender: TObject);
begin
  fmMain.lbXdataConnected.caption:='Xdata connected';
  fmMain.lbXdataConnected.visible:=true;
end;

procedure TfmDataModule.XDataWebConnection1Error(
  Error: TXDataWebConnectionError);
begin
  fmMain.lbXdataConnected.caption:='Xdata could not connect';
  fmMain.lbXdataConnected.visible:=true;
end;

procedure TfmDataModule.LoadDFMValues;
begin
  inherited LoadDFMValues;

  XDataWebConnection1 := TXDataWebConnection.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);

  XDataWebConnection1.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  try
    Name := 'fmDataModule';
    Height := 231;
    Width := 306;
    XDataWebConnection1.SetParentComponent(Self);
    XDataWebConnection1.Name := 'XDataWebConnection1';
    XDataWebConnection1.URL := 'https://lslabtest.dyndns-server.com:8035/';
    SetEvent(XDataWebConnection1, Self, 'OnConnect', 'XDataWebConnection1Connect');
    SetEvent(XDataWebConnection1, Self, 'OnError', 'XDataWebConnection1Error');
    XDataWebConnection1.Left := 89;
    XDataWebConnection1.Top := 26;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Connection := XDataWebConnection1;
    XDataWebClient1.Left := 81;
    XDataWebClient1.Top := 103;
  finally
    XDataWebConnection1.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
  end;
end;

end.
