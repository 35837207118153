unit libexceljs;

interface

{$mode objfpc}
{$modeswitch externalclass}

uses
  Classes, js, web, types;

type
  TJSExcelJSColor = class external name 'Object' (TJSObject)
    argb: string;
	  theme: Integer;
  end;

  TJSExcelJSFillPattern = class external name 'Object' (TJSObject)
    &type: string;
    pattern: string; // 'none' | 'solid' | 'darkVertical' | 'darkHorizontal' | 'darkGrid' | 'darkTrellis' | 'darkDown' | 'darkUp' | 'lightVertical' | 'lightHorizontal' | 'lightGrid' | 'lightTrellis' | 'lightDown' | 'lightUp' | 'darkGray' | 'mediumGray' | 'lightGray' | 'gray125' | 'gray0625'
    fgColor: TJSExcelJSColor;
    bgColor: TJSExcelJSColor;
  end;

  TJSExcelJSFill = class external name 'Object' (TJSObject)
    &type: string;
    gradient: string; // 'angle' | 'path'
    stops: TJSArray;
  end;

  TJSExcelJSFillGradientAngle = class external name 'Object' (TJSExcelJSFill)
    degree: NativeInt;
  end;

  TJSExcelJSFillGradientPathCenter = class (TJSObject)
    left: NativeInt;
    top: NativeInt;
  end;

  TJSExcelJSFillGradientPath = class external name 'Object' (TJSExcelJSFill)
    center: TJSExcelJSFillGradientPathCenter;
  end;

  TJSExcelJSFont = class external name 'Object' (TJSObject)
    name: string;
    size: Integer;
    family: Integer;
    scheme: string;
    charset: Integer;
    color: TJSExcelJSColor;
    bold: Boolean;
    italic: Boolean;
    underline: JSValue; //Boolean | 'none' | 'single' | 'double' | 'singleAccounting' | 'doubleAccounting'
    vertAlign: string;
    strike: Boolean;
    outline: Boolean;
  end;

  TJSExcelJSBorder = class external name 'Object' (TJSObject)
    style: string;
	  color: TJSExcelJSColor;
  end;

  TJSExcelJSBorderDiagonal = class external name 'Object' (TJSExcelJSBorder)
    up: Boolean;
	  down: Boolean;
  end;

  TJSExcelJSBorders = class external name 'Object' (TJSObject)
    top: TJSExcelJSBorder;
    left: TJSExcelJSBorder;
    bottom: TJSExcelJSBorder;
    right: TJSExcelJSBorder;
    diagonal: TJSExcelJSBorderDiagonal;
  end;

  TJSExcelJSAlignment = class external name 'Object' (TJSObject)
    horizontal: string;
    vertical: string;
    wrapText: Boolean;
    shrinkToFit: Boolean;
    indent: Integer;
    readingOrder: string;
    textRotation: JSValue; //Integer | 'vertical'
  end;

  TJSExcelJSProtection = class external name 'Object' (TJSObject)
    locked: Boolean;
    hidden: Boolean;
  end;

  TJSExcelJSStyle = class external name 'Style' (TJSObject)
    alignment: TJSExcelJSAlignment;
    font: TJSExcelJSFont;
    protection: TJSExcelJSProtection;
    border: TJSExcelJSBorders;
    fill: TJSObject; //FillPattern | FillGradientAngle | FillGradientPath
    numFmt: string;
  end;

  TJSExcelJSCell = class external name 'Cell' (TJSExcelJSStyle)
    text: string;
    isHyperLink: Boolean;
    isMerged: Boolean;
    name: string;
    names: TStringDynArray;
    value: JSValue;
    &type: Integer;
    procedure addName(AName: string);
    procedure removeName(AName: string);
    procedure release;
    procedure merge(AMaster: TJSExcelJSCell); overload;
    procedure merge(AMaster: TJSExcelJSCell; AIgnoreStyle: Boolean); overload;
    procedure unmerge;
    function isMergedTo(AMaster: TJSExcelJSCell): Boolean;
    function toCsvString: string;
    function toString: string; reintroduce;
  end;

  TJSExcelJSRowEachCellCallback = reference to procedure(ACell: TJSExcelJSCell; AColNumber: Integer);

  TJSExcelJSRow = class external name 'Row' (TJSExcelJSStyle)
    height: Integer;
    hidden: Boolean;
    procedure eachCell(AOption: TJSObject; ACallback: TJSExcelJSRowEachCellCallback);
  end;

  TJSExcelJSWorksheetEachRowCallback = reference to procedure(ARow: TJSExcelJSRow; ARowNumber: Integer);

  TJSExcelJSWorksheet = class external name 'Worksheet'(TJSObject)
    rowCount: Integer;
    actualRowCount: Integer;
    columnCount: Integer;
    actualColumnCount: Integer;
    name: string;
    procedure commit;
    function getSheetValues: TJSArray;
    function getCell(ARow: Integer; AColumn: Integer): TJSExcelJSCell;
    procedure eachRow(AOption: TJSObject; ACallback: TJSExcelJSWorksheetEachRowCallback);
    procedure addRow(values: TJSValueDynArray);
  end;

  TJSExcelJSXLSX = class external name 'xlsx' (TJSObject)
    function load(AData: TJSArrayBuffer): TJSPromise;
    function writeBuffer: TJSPromise;
    function writeBuffer(AOptions: TJSObject): TJSPromise;
  end;

  TJSExcelJSCSV = class external name 'csv' (TJSObject)
    function writeBuffer: TJSPromise;
  end;

  TJSExcelJSWorksheets = array of TJSExcelJSWorksheet;

  TJSExcelJSWorkbook = class external name 'Workbook' (TJSObject)
    xlsx: TJSExcelJSXLSX;
    csv: TJSExcelJSCSV;
    worksheets: TJSExcelJSWorksheets;
    creator: string;
    lastModifiedBy: string;
    function getWorksheet(ASheetName: string): TJSExcelJSWorksheet; overload;
    function getWorksheet(ASheetIndex: Integer): TJSExcelJSWorksheet; overload;
    function addWorksheet: TJSExcelJSWorksheet; overload;
    function addWorksheet(ASheetName: string): TJSExcelJSWorksheet; overload;
    function addWorksheet(ASheetName: string; AOptions: TJSObject): TJSExcelJSWorksheet; overload;
    procedure removeWorksheet(ASheetName: string); overload;
    procedure removeWorksheet(ASheetIndex: Integer); overload;
  end;

  TJSExcelJSWorkbookClass = class of TJSExcelJSWorkbook;

  TJSExcelJS = class external name 'ExcelJS' (TJSObject)
    Workbook: TJSExcelJSWorkbookClass;
  end;


var
  ExcelJS: TJSExcelJS; external name 'ExcelJS';

implementation

end.
